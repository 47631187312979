<template>
   <div>
      <div class="content_element content_blocks_element">
         <div class="container">
            <div class="two-pic-layout">
               <div class="container">
                  <div class="row">
                     <div :class="{
                        'col-md-6' : content.items.length % 2 === 0,
                        'col-md-4' : content.items.length % 3 === 0,
                     }" v-for="item in content.items" :key="item.id">
                        <a href="#" @click.prevent="modalContent = item">
                           <figure>
                              <img :src="cmsAsset(item.image)" alt="Abellio Rail Mitteldeutchland">
                              <figcaption>{{ item.image_caption }}</figcaption>
                           </figure>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <Modal
         :additionalClass="'map-modal'"
         @modalClose="modalContent = false"
         :visible="modalContent"
      >
         <template slot="modalContent">
            <h3>{{ modalContent.title }}</h3>
            <img :src="cmsAsset(modalContent.image)" alt="" />
            <div v-html="modalContent.content"></div>
         </template>
      </Modal>
   </div>
</template>

<script>
   import Modal from "@/components/_common/Modal";

   export default {
      name: "ImagesRow",
      components: {
         Modal
      },
      props: {
         content: {
            type: Object,
            required: true
         }
      },
      data() {
         return {
            modalContent: false
         }
      }
   }
</script>
