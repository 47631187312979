<template>
    <div class="content_element content_blocks_element">
        <div class="container">
            <h2 class="text-center mb50" v-if="content.title">{{content.title}}</h2>
            <div :tabindex="index+1" class="row content_block_item" :class="{ 'flex-row-reverse': index % 2 == 0 }" v-for="(item, index) in stationsList" :key="index+'stations_list'">
                <!--<div class="col-12 col-md-6 mb30" :class="{ 'flex100': !item.gallery[0].length }">-->
                <div class="col-12 col-md-6 mb30" >
                    <div class="content_block_content d-flex flex-column justify-content-between h-100">
                        <div>
                            <h2>{{item.title}}</h2>
                            <div>
                                <p>
                                    <template v-if="item.street">{{item.street}} <br/></template>
                                    <template v-if="item.zip">{{item.zip}} <br/></template>
                                    {{item.city.title}}
                                </p>
                            </div>
                        </div>
                        <a href="#" class="info_tag_btn more_btn d-inline-flex justify-content-between">
                            <span class="color"></span>
                            {{item.title}}
                            <span class="info_tag_arrow d-flex align-items-center">lesen</span>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb30">
                    <div class="content_block_image" :style="{ backgroundImage:  'url('+assetsPath+'/test_image.jpg)'}">
                        <img :src="assetsPath+'/test_image.jpg'" :alt="item.title">
                    </div>
                </div>
                <!--<div class="col-12 col-md-6 mb30" v-if="!item.gallery[0].length">
                    <div class="content_block_image" :style="{ backgroundImage:  'url('+assetsPath+item.gallery[0].image+')'}">
                        <img :src="assetsPath+item.gallery[0].image" :alt="item.title">
                    </div>
                </div>-->
            </div>
            <div class="text-center station_loader_btn_wrapper">
                <button class="btn mb0"
                        @click.prevent="loadMore"
                        :disabled="btnDisable"
                        v-if="!btnHidden"
                >Mehr laden</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {config} from "../../config";

    export default {
        name: "StationsList",
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                btnDisable: false,
                btnHidden: true,
                page: 1,
                stationsList: []
            }
        },
        computed: {
            ...mapState([
                'station',
            ]),
            assetsPath() {
                return config.apiUrl + '/storage/app/media';
            }
        },
        methods: {
            loadMore: function() {
                this.btnDisable = true;
                this.$store.dispatch('station/getList', {
                    sort_by: this.content.sort_by,
                    order: this.content.order,
                    limit: this.content.limit,
                    page: this.page
                });
            }
        },
        created() {
            this.$store.dispatch('station/getList', {
                sort_by: this.content.sort_by,
                order: this.content.order,
                limit: this.content.limit,
                page: this.page
            });
        },
        mounted() {
            if(this.content.limit <= 0) {
                this.btnHidden = true;
            }
        },
        beforeDestroy() {
            this.$store.commit('station/setList', {});
        },
        watch: {
            'station.list': function (val) {
                if(val.data && val.data.length) {
                    this.stationsList.push(...val.data);
                    this.btnDisable = false;
                    if (val.next_page_url) {
                        this.page = parseInt(val.next_page_url.split('?page=')[1]);
                        this.btnHidden = false;
                    } else {
                        this.btnHidden = true;
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
