<template>
   <div class="content_element accordion_element news_blog_wrapper">
      <div class="container">
         <div class="row">
            <template v-for="(news, index) in news.all">
               <transition appear name='slide-fade' :key="index">
                  <div class="col-xl-4 col-lg-6 col-md-12 blog-card-holder custom-card" :key="index">
                     <div class="blog-card large">
                        <div class="inner">
                           <router-link :to="{name: 'news', params: {slug: news.slug}}">
                              <div class="blog-card-content">
                                 <div class="blog-card-content-top">
                                    <h3 class="title">{{ news.title }}</h3>
                                 </div>
                                 <div class="blog-card-content-bottom">
                                    <p>{{ news.teaser }}</p>
                                    <span>&nbsp;</span>

                                    <div class="date" v-if="news.published_date">
                                       <p>{{ news.published_date }}</p>
                                    </div>
                                 </div>
                              </div>
                           </router-link>
                        </div>
                     </div>
                  </div>
               </transition>
            </template>
            <div class="col-sm-12 text-center my-2" v-if="hasMore">
               <button class="red-button load-more" @click.stop="loadNews" v-if="!loading">{{ __t("news.load_more") }}</button>
               <button class="red-button load-more" v-if="loading">{{ __t("news.loading") }}</button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {mapState} from "vuex";

export default {
   name: "NewsList",
   components: {},
   props: {
      content: {
         type: Object,
         required: true
      }
   },
   data() {
      return {
         pageNr: 0,
         hasMore: true,
         loading: true,
         firstLoad: true,
      }
   },
   computed: {
      ...mapState(['news']),
   },
   methods: {
      loadNews() {
         let self = this
         this.loading = true
         this.pageNr += 1

         this.$store.dispatch('news/loadAll', this.pageNr).then(function (resp) {
            self.loading = false
            self.hasMore = resp.has_more
         });
      }
   },
   created() {
      this.$store.dispatch('news/resetNews')
      this.loadNews()
   }
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
   transition: all .3s ease;
}

.slide-fade-leave-active {
   transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
   transform: translateX(10px);
   opacity: 0;
}

.filter_list .active a {
   background: $abellio-red;
}
</style>
