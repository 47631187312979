<template>
    <div class="content_element accordion_element">
        <div class="container">

            <div class="row" v-if="content.title">
                <div class="col-12 mb16">
                    <h3 class="mb-0">{{ content.title }}</h3>
                </div>
            </div>

            <div class="accordion_wrapper">
                <div class="accordion_item" :class="{ 'is_open' : activeAccordions.includes(index) }" @click="toggleAccordion(index)" v-for="(item, index) in content.rows" :key="index + 'accordion'"
                     :tabindex="1000 + index">
                    <h6 class="accordion_title mb0">{{item.title}}</h6>
                    <transition name="slide">
                        <div class="accordion_content html-content" v-if="activeAccordions.includes(index)" v-html="item.content"></div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Accordion",
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                activeAccordions: []
            }
        },
        methods: {
            toggleAccordion(id) {
                if (this.activeAccordions.includes(id)) {
                    this.activeAccordions = this.activeAccordions.filter(i => i !== id);
                    return;
                }

                this.activeAccordions.push(id);
            }
        }
    }
</script>
