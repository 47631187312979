<template>
   <div>
      <div class="content_element content_blocks_element">
         <!-- Image and Content section  -->
         <div class="image-content">
            <div class="container">
               <div class="row">
                  <div class="col-xl-6 col-lg-6 col-12 image-content-img">
                     <img :src="cmsAsset(content.image)" width="100%" :alt="content.title" />
                  </div>
                  <div class="col-xl-6 col-lg-6 col-12 image-content-content">
                     <div class="image-content-content-in">
                        <h3 v-text="content.title"></h3>
                        <p v-html="content.content"></p>
                     </div>
                     <a v-if="content.buttonText != ''" :href="content.buttonUrl" class="link-style" v-text="content.buttonText"></a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   export default {
      name: "ImageAndContent",
      props: {
         content: {
            type: Object,
            required: true
         }
      }
   }
</script>
