<template>
   <div class="content_element form_builder_element">
      <div class="container">
         <div class="row justify-content-center">
            <div class="col-12 ">
               <h4 v-if="content.title && showTitle">{{ content.title }}</h4>
               <p>{{ content.subtitle }}</p>
               <vue-form
                  v-if="!formSent"
                  :state="formState"
                  @submit.prevent="onSubmit"
                  :id="'formBuilder_' + content.id"
               >
                  <input
                     type="hidden"
                     name="template"
                     :value="content.template"
                  />
                  <div class="row">
                     <input
                        type="hidden"
                        name="Stellenangebot"
                        :value="pageTitle"
                        v-if="showHiddenInput"
                     />

                     <div
                        v-for="(item, index) in fields"
                        :class="item.css_class"
                        :key="'input_' + content.id + '_' + index"
                     >
                        <validate
                           v-if="item._group == 'text'"
                           :tabindex="index + 1"
                        >
                           <InputText
                              :name="item.name"
                              :label="item.name"
                              :placeholder="item.placeholder"
                              :inputId="'input_' + content.id + '_' + index"
                              v-model="model[index]"
                              :required="item.is_required == 1"
                              :disabled="false"
                           />
                           <field-messages
                              :name="item.name"
                              class="vf-error_message"
                           >
                              <template slot="required" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted"
                                    >{{ item.name }} ist ein Pflichtfeld</span
                                 >
                              </template>
                           </field-messages>
                        </validate>
                        <validate
                           v-else-if="item._group == 'textarea'"
                           :tabindex="index + 1"
                        >
                           <InputTextarea
                              :name="item.name"
                              :label="item.name"
                              :placeholder="item.placeholder"
                              :inputId="'input_' + content.id + '_' + index"
                              v-model="model[index]"
                              :required="item.is_required == 1"
                              :disabled="false"
                           />
                           <field-messages
                              :name="item.name"
                              class="vf-error_message"
                           >
                              <template slot="required" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted"
                                    >{{ item.name }} ist ein Pflichtfeld</span
                                 >
                              </template>
                           </field-messages>
                        </validate>
                        <validate
                           v-else-if="item._group == 'email'"
                           :tabindex="index + 1"
                        >
                           <InputText
                              :name="item.name"
                              :label="item.name"
                              :placeholder="item.placeholder"
                              :inputId="'input_' + content.id + '_' + index"
                              v-model="model[index]"
                              :required="item.is_required == 1"
                              type="email"
                              :disabled="false"
                           />
                           <field-messages
                              :name="item.name"
                              class="vf-error_message"
                           >
                              <template slot="required" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted"
                                    >{{ item.name }} ist ein Pflichtfeld</span
                                 >
                              </template>
                              <template slot="email" slot-scope="state">
                                 <span v-if="!state.$valid && state.$dirty"
                                    >eine gültige E-Mail eingeben</span
                                 >
                              </template>
                           </field-messages>
                        </validate>
                        <validate
                           v-else-if="item._group == 'dropdown'"
                           :tabindex="index + 1"
                        >
                           <InputSelect
                              :name="item.name"
                              :label="item.name"
                              :placeholder="item.placeholder"
                              :inputId="'input_' + content.id + '_' + index"
                              v-model="model[index]"
                              :options="stringToOptions(item.options)"
                              :required="item.is_required == 1"
                              :disabled="false"
                           />
                           <field-messages
                              :name="item.name"
                              class="vf-error_message"
                           >
                              <template slot="required" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted"
                                    ><strong>{{ item.name }}</strong
                                    >: Bitte eine Option auswählen.</span
                                 >
                              </template>
                           </field-messages>
                        </validate>
                        <validate
                           v-else-if="item._group == 'checkboxes'"
                           :tabindex="index + 1"
                        >
                           <InputCheckboxes
                              :name="item.name"
                              :label="item.label"
                              :inputId="'input_' + content.id + '_' + index"
                              v-model="model[index]"
                              :options="stringToOptions(item.options)"
                              :required="item.is_required == 1"
                              :disabled="false"
                           />
                           <field-messages
                              :name="item.name"
                              class="vf-error_message"
                           >
                              <template slot="required" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted"
                                    ><strong>{{ item.name }}</strong
                                    >: Bitte eine Option auswählen.</span
                                 >
                              </template>
                           </field-messages>
                        </validate>
                        <validate
                           v-else-if="item._group == 'draggable'"
                           :tabindex="index + 1"
                        >
                           <InputDraggable
                              :name="item.name"
                              :label="item.label"
                              :inputId="'input_' + content.id + '_' + index"
                              v-model="model[index]"
                              :options="stringToOptions(item.options)"
                              :required="item.is_required == 1"
                              :disabled="false"
                           />
                           <field-messages
                              :name="item.name"
                              class="vf-error_message"
                           >
                              <template slot="required" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted"
                                    ><strong>{{ item.name }}</strong
                                       >: Sie können die Antworten neu sortieren.</span
                                 >
                              </template>
                           </field-messages>
                        </validate>
                        <validate
                           v-else-if="item._group == 'radio_buttons'"
                           :tabindex="index + 1"
                        >
                           <InputRadioButtons
                              :name="item.name"
                              :label="item.label"
                              :inputId="'input_' + content.id + '_' + index"
                              v-model="model[index]"
                              :options="stringToOptions(item.options)"
                              :required="item.is_required == 1"
                              :disabled="false"
                           />
                           <field-messages
                              :name="item.name"
                              class="vf-error_message"
                           >
                              <template slot="required" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted"
                                    ><strong>{{ item.name }}</strong
                                    >: Bitte eine Option auswählen.</span
                                 >
                              </template>
                           </field-messages>
                        </validate>
                     </div>
                  </div>
                  <div class="d-flex flex-wrap">
                     <div
                        class="flex-grow-1 pr30"
                        v-if="
                           content.confirmation_checkbox &&
                              content.confirmation_checkbox_error
                        "
                     >
                        <validate :tabindex="fields.length + 1">
                           <InputCheckboxes
                              name="confirmation_checkbox"
                              :inputId="'confirmation_checkbox' + content.id"
                              v-model="confirmationCheckbox"
                              :options="[
                                 {
                                    key: 'true',
                                    value: content.confirmation_checkbox
                                 }
                              ]"
                              :required="true"
                              :disabled="false"
                           />
                           <field-messages
                              name="confirmation_checkbox"
                              class="vf-error_message"
                           >
                              <template slot="required" slot-scope="state">
                                 <span
                                    v-if="state.$dirty || state.$submitted"
                                    >{{
                                       content.confirmation_checkbox_error
                                    }}</span
                                 >
                              </template>
                           </field-messages>
                        </validate>
                     </div>
                     <div class="">
                        <input
                           type="hidden"
                           name="form_id"
                           v-model="model['id']"
                        />

                        <button :tabindex="fields.length + 2" type="submit">
                           <template v-if="formSending"
                              >{{ __t("send") }} ...</template
                           >
                           <template v-else>{{ __t("send") }}</template>
                        </button>
                     </div>
                  </div>
                  <div>
               </div>
               </vue-form>


               <div v-else class="d-flex flex-column prelative success-message">
                  <svg
                     version="1.1"
                     id="Capa_1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     x="0px"
                     y="0px"
                     viewBox="0 0 50 50"
                     style="enable-background: new 0 0 50 50"
                     xml:space="preserve"
                  >
                     <circle style="fill: #2e2e2e" cx="25" cy="25" r="25" />
                     <polyline
                        style="
          fill: none;
          stroke: #fff;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
         "
                        points="
	38,15 22,33 12,25 "
                     />
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                     <g></g>
                  </svg>
                  <h2 class="mb30">
                     <h2 v-text="content.success_message.title"></h2>
                  </h2>
                  <p>
                     {{  content.success_message.subtitle}}
                  </p>
                  <!-- <nl2br :text="__t('form.successfully_sent_desc')" :tag="'p'"/> -->

                  <a
                     href="#"
                     class="button-ok"
                     @click.prevent="formSent = false"
                  >
                     {{ __t("form.successfully_sent_button") }}
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {
   InputText,
   InputSelect,
   InputTextarea,
   InputCheckboxes,
   InputRadioButtons,
   InputDraggable
} from "../../components/_form";

export default {
   name: "FormBuilder",
   components: {
      InputText,
      InputSelect,
      InputTextarea,
      InputCheckboxes,
      InputRadioButtons,
      InputDraggable
   },
   props: {
      id: {
         type: Number,
         required: true
      },
      content: {
         type: Object,
         required: true
      },
      showTitle: {
         type: Boolean,
         default: true
      }
   },
   data() {
      return {
         formState: {},
         model: [],
         confirmationCheckbox: null,
         formSent: false,
         formSending: false,
         pageTitle: "",
         show: false
      };
   },
   computed: {
      fields() {
         return this.content.form_elements;
      },
      showHiddenInput() {
         return this.$route.path.startsWith("/jobs");
      }
   },
   methods: {
      stringToOptions(options) {
         let array = [];
         let optionsArray = options.split("\n");

         for (let i = 0; i < optionsArray.length; i++) {
            if (optionsArray[i].length > 1) {
               array.push({
                  key: optionsArray[i].trim(),
                  value: optionsArray[i].trim()
               });
            }
         }

         return array;
      },
      onSubmit: function() {
         if (this.formState.$invalid) {
            document.getElementsByClassName("vf-field-invalid")[0].focus();
            return;
         }
         if (this.formSending) {
            return;
         }
         this.formSending = true;

         let myForm = document.getElementById("formBuilder_" + this.content.id);
         let formData = new FormData(myForm);
         this.$store
            .dispatch("form/submitFormBuilder", {
               formData: formData,
               message: {
                  success_message: this.content.success_message,
                  error_message: this.content.error_message
               }
            })
            .then(() => {
               this.model = [];
               this.model.id = this.id;
               this.confirmationCheckbox = null;
               this.formState._reset();
               this.formSending = false;
               this.formSent = true;
               document.getElementsByClassName("vf-field-pristine")[0].focus();
            });
      }
   },
   mounted() {
      this.model.id = this.id;
      this.pageTitle = document.querySelector("h1.title")?.textContent;
   }
};
</script>

<style scoped></style>
