<template>
   <div class="content_element abellio_world_element">
      <div class="job_offers_map_element">
         <div class="map_zip_code_cannger_wrapper" :class="{ 'right-side' : zipCodeFound }">
            <p class="mb-2">{{ __t('world.find_location_desc')}}</p>
            <div class="d-flex justify-content-center">
               <InputText
                  name="abellio_world_zip_code"
                  label="Adresse"
                  placeholder=""
                  inputId="abellio_world_address"
                  v-model="zipCode"
                  @keyupEnter="searchLocation"
               />
               <a href="#" @click.prevent="searchLocation"
                  class="plus_button d-flex justify-content-center align-items-center align-self-center">
                  <i class="ab-icon-arrow-right"></i>
               </a>
            </div>
         </div>
         <GmapMap
            ref="mapRef"
            :center="{lat: currentLocation.lat, lng: currentLocation.lng}"
            :zoom="mapZoom"
            :options="mapOptions"
            class="job_offers_map_wrapper"
            :class="{ is_visible: showMap }"
            @bounds_changed="boundFilteredMarkers()">

            <GmapMarker
               ref="currentLocationMarker"
               v-if="zipCodeFound"
               :position="currentMarkerLocation"
               :icon="{url: cmsAsset('markers/marker_abellio_cluster.png')}"/>

            <AbellioWorldLines
               ref="linesRef"
               :settings="settings"
               @showContent="showContent"
               @markerClicked="markerClicked"
               v-if="content.with_lines == '1' && linesActive"/>

            <GmapCluster
               :calculator="calculator"
               ref="gMapClusters"
               :zoom-on-click="true"
               :styles="[
                    {
                        textColor: '#ffffff',
                        width: 30,
                        height: 30,
                        icon: 'data:image/gif;base64,R0lGODlhAQABAAAAACw='
                    }
                ]">
               <AbellioWorldStations
                  ref="stationsRef"
                  :settings="settings"
                  @showContent="showContent"
                  @markerClicked="markerClicked"
                  v-if="content.with_stations == '1' && stationsActive"/>

               <AbellioWorldTicketSalesPoints
                  ref="salesPointsRef"
                  :settings="settings"
                  @showContent="showContent"
                  @markerClicked="markerClicked"
                  v-if="content.with_sales_points == '1' && salesPointsActive"/>
            </GmapCluster>

            <AbellioWorldEvents
               ref="eventsRef"
               :settings="settings"
               @showContent="showContent"
               @markerClicked="markerClicked"
               v-if="content.with_events == '1' && eventsActive"/>

            <AbellioWorldAbellioOffices
               ref="abellioOfficeRef"
               :settings="settings"
               @showContent="showContent"
               @markerClicked="markerClicked"
               v-if="content.with_abellio == '1' && abellioActive"/>

         </GmapMap>
      </div>

      <div class="d-flex justify-content-center flex-wrap abellio_world_filter_wrapper"
           v-if="!isMobile || isMobile && !showAbellioWorldMobileContent"
           :class="{is_open: abellioWorldMobileFilter}">

         <a href="#" class="abellio_world_filter_close"
            @click.prevent="abellioWorldMobileFilter = !abellioWorldMobileFilter"></a>

         <InputSelect
            class="region-changer abellio_world_filter_region"
            name="input_region"
            label="Ihre Region"
            inputId="input_region"
            v-model="currentRegion"
            :options="availableRegions"
         />

         <a href="#" class="abellio_world_filter_trigger d-flex flex-column align-items-center"
            :class="{ is_passive: !linesActive }"
            @click.prevent="filterMarkers('lines')"
            v-if="content.with_lines == '1'"
         >
            <i class="icon d-flex justify-content-center align-items-center ab-icon-transport-line"
               :style="{ backgroundColor: settings.all.lines_color }"
            >
            </i>
            {{ settings.all.lines_title }}
         </a>

         <a href="#" class="abellio_world_filter_trigger d-flex flex-column align-items-center"
            :class="{ is_passive: !stationsActive }"
            @click.prevent="filterMarkers('stations')"
            v-if="content.with_stations == '1'"
         >
            <i class="icon d-flex justify-content-center align-items-center ab-icon-train"
               :style="{ backgroundColor: settings.all.stations_color }"
            ></i>
            {{ settings.all.stations_title }}
         </a>

         <a href="#" class="abellio_world_filter_trigger d-flex flex-column align-items-center"
            :class="{ is_passive: !salesPointsActive }"
            @click.prevent="filterMarkers('sales_points')"
            v-if="content.with_sales_points == '1'"
         >
            <i class="icon d-flex justify-content-center align-items-center ab-icon-sales-point"
               :style="{ backgroundColor: settings.all.sales_points_color }"
            ></i>
            {{ settings.all.sales_points_title }}
         </a>

         <a href="#" class="abellio_world_filter_trigger d-flex flex-column align-items-center"
            :class="{ is_passive: !eventsActive }"
            @click.prevent="filterMarkers('events')"
            v-if="content.with_events == '1'"
         >
            <i class="icon d-flex justify-content-center align-items-center ab-icon-events"
               :style="{ backgroundColor: settings.all.events_color }"
            ></i>
            {{ settings.all.events_title }}
         </a>

         <a href="#" class="abellio_world_filter_trigger d-flex flex-column align-items-center"
            :class="{ is_passive: !abellioActive }"
            @click.prevent="filterMarkers('abellio')"
            v-if="content.with_abellio == '1'"
         >
            <i class="icon d-flex justify-content-center align-items-center ab-icon-headquarters"
               :style="{ backgroundColor: settings.all.abellio_color }"
            ></i>
            {{ settings.all.abellio_title }}
         </a>

      </div>

      <div class="abellio_world_mobile_content_wrapper" v-if="!isMobile || isMobile && showAbellioWorldMobileContent"
           :class="{is_open: showAbellioWorldMobileContent}">
         <a href="#" class="abellio_world_filter_close"
            @click.prevent="showAbellioWorldMobileContent = !showAbellioWorldMobileContent"></a>
         <a href="#" class="abellio_world_filter_close_button"
            @click.prevent="showAbellioWorldMobileContent = false">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21.5"
                 height="21.5" viewBox="0 0 21.5 21.5">
               <g id="Group_3" data-name="Group 3" transform="translate(0 0)" clip-path="url(#clip-path)">
                  <path id="Fill_1" data-name="Fill 1"
                        d="M10.75,21.5A10.75,10.75,0,1,1,21.5,10.75,10.762,10.762,0,0,1,10.75,21.5Zm0-9.69h0l4.416,4.419,1.061-1.061L11.811,10.75,16.23,6.33,15.169,5.27,10.751,9.689,6.331,5.27,5.27,6.33l4.42,4.42L5.27,15.169l1.06,1.061,4.42-4.419Z"
                        transform="translate(0 0)" fill="#c6c6c6"/>
               </g>
            </svg>
         </a>
         <div class="abellio_world_mobile_content_inner">
            <AbellioWorldCarouselStationsContent
               v-if="contentType == 'station' && contentID && filteredStations.length"
               :content="{
                        title: settings.all.stations_title,
                        type: contentType,
                        items: filteredStations
                    }"
               :id="contentID"
            />
            <AbellioWorldCarouselTicketSalesPointsContent
               v-if="contentType == 'sales-point' && contentID && filteredSalesPoints.length"
               :content="{
                        title: settings.all.sales_points_title,
                        type: contentType,
                        items: filteredSalesPoints
                    }"
               :id="contentID"
            />

            <AbellioWorldCarouselEventsContent
               v-if="contentType == 'event' && contentID && filteredEvents.length"
               :content="{
                        title: settings.all.events_title,
                        type: contentType,
                        items: filteredEvents
                    }"
               :id="contentID"
            />
            <AbellioWorldCarouselContent
               v-if="contentType == 'office' && contentID && filteredAbellioOffices.length"
               :content="{
                        title: settings.all.abellio_title,
                        type: contentType,
                        items: filteredAbellioOffices
                    }"
               :id="contentID"
            />
         </div>
      </div>
   </div>
</template>

<script>
   import $ from 'jquery';
   import {mapState} from 'vuex';
   import {gmapApi} from 'vue2-google-maps';
   import {InputText} from '../../components/_form';
   import AbellioWorldLines from "./_sub_elements/AbellioWorldLines";
   import AbellioWorldStations from "./_sub_elements/AbellioWorldStations";
   import AbellioWorldTicketSalesPoints from "./_sub_elements/AbellioWorldTicketSalesPoints";
   import AbellioWorldEvents from "./_sub_elements/AbellioWorldEvents";
   import AbellioWorldAbellioOffices from "./_sub_elements/AbellioWorldAbellioOffices";
   import AbellioWorldCarouselContent from "./_sub_elements/AbellioWorldCarouselContent";
   import AbellioWorldCarouselEventsContent from "./_sub_elements/AbellioWorldCarouselEventsContent";
   import AbellioWorldCarouselStationsContent from "./_sub_elements/AbellioWorldCarouselStationsContent";
   import AbellioWorldCarouselTicketSalesPointsContent
      from "./_sub_elements/AbellioWorldCarouselTicketSalesPointsContent";
   import InputSelect from "../_form/InputSelect";

   export default {
      name: "AbellioWorld",
      components: {
         InputSelect,
         AbellioWorldCarouselTicketSalesPointsContent,
         InputText,
         // AbellioWorldJobs,
         AbellioWorldLines,
         AbellioWorldStations,
         AbellioWorldEvents,
         AbellioWorldAbellioOffices,
         AbellioWorldCarouselContent,
         AbellioWorldTicketSalesPoints,
         AbellioWorldCarouselEventsContent,
         AbellioWorldCarouselStationsContent
      },
      props: {
         content: {
            type: Object,
            required: true
         }
      },
      data() {
         return {
            mapOptions: {
               zoomControl: true,
               zoomControlOptions: {
                  position: 3
               },
               mapTypeControl: false,
               scaleControl: false,
               streetViewControl: false,
               rotateControl: true,
               fullscreenControl: false,
               disableDefaultUi: false,
               gestureHandling: 'greedy',
            },
            showMap: false,
            mapZoom: 14,
            currentMapZoom: 0,
            currentLocation: {
               lat: 0,
               lng: 0
            },
            currentMarkerLocation: {
               lat: 0,
               lng: 0
            },
            zipCode: '',

            linesActive: true,
            stationsActive: true,
            //ticketMachinesActive: true,
            salesPointsActive: true,
            // jobsActive: true,
            eventsActive: true,
            abellioActive: true,

            filteredLines: [],
            filteredStations: [],
            filteredSalesPoints: [],
            filteredJobs: [],
            filteredEvents: [],
            filteredAbellioOffices: [],

            salesPointActiveId: 0,
            // jobActiveId: 0,
            eventActiveId: 0,
            abellioActiveId: 0,

            isMobile: false,
            abellioWorldMobileFilter: false,
            showAbellioWorldMobileContent: false,

            zipCodeFound: false,
            contentID: null,
            contentType: null,
            currentRegion: this.$store.state.region
               ? this.$store.state.region.currentRegion.id
               : 1,
         }
      },
      computed: {
         ...mapState([
            'settings',
            'region',
            'line',
            'station',
            'ticketSalesPoint',
            // 'job',
            'events',
            'office',
         ]),
         google: gmapApi,
         allFIltersActive() {
            if (
               (this.linesActive || this.content.with_lines != '1') &&
               (this.stationsActive || this.content.with_stations != '1') &&
               (this.salesPointsActive || this.content.with_sales_points != '1') &&
               // (this.jobsActive || this.content.with_jobs != '1') &&
               (this.eventsActive || this.content.with_events != '1') &&
               (this.abellioActive || this.content.with_abellio != '1')
            ) {
               return true;
            }
            return false;
         },
         isContentVisible() {
            if (this.currentMapZoom > 10) {
               return true;
            }
            return false;
         },
         availableRegions: function () {
            const result = [];
            this.region.all.forEach((region) => {
               result.push({
                  key: region.id,
                  value: region.title,
               });
            });
            return result;
         },
      },
      methods: {
         calculator: function (markers) {
            const groupedMarkers = [
               [],
               []
            ];

            for (let i = 0; i < markers.length; i++) {
               groupedMarkers[markers[i].type].push(markers[i]);
            }

            let html = '<div class="category-marker ' + ((groupedMarkers[0].length === 0) ? 'station-only' : '') + ((groupedMarkers[1].length === 0) ? 'sales-only' : '') + '"><span class="counter">' + markers.length + '</span>';

            if (groupedMarkers[0].length > 0 && groupedMarkers[1].length > 0)
               groupedMarkers.forEach(function (item, index) {
                  if (item.length)
                     html += '<div class="category-marker__inner" data-type="' + index + '">' + item.length + '</div>';
               });

            html += '</div>';

            return {
               text: html,
               index: 0
            };
         },
         carouselWrapperWidth: function () {
            let element = $(this.$el).find('.carousel_content_wrapper');
            if (element.length) {
               let offsetLeft = element.offset().left;
               element.outerWidth($(this.$el).outerWidth() - offsetLeft);

               this.$nextTick(() => {
                  if (this.$refs.swiperCarousel) {
                     this.$refs.swiperCarousel.$swiper.update();
                  }
               });
            }

            if (window.innerWidth < 768) {
               this.isMobile = true;
            } else {
               this.isMobile = false;
            }
         },
         filterMarkers(type) {
            if (type == 'lines') {
               this.linesActive = !this.linesActive;
            } else if (type == 'stations') {
               this.stationsActive = !this.stationsActive;
            } else if (type == 'sales_points') {
               this.salesPointsActive = !this.salesPointsActive;
               // }
               // else if (type == 'jobs') {
               //   this.jobsActive = !this.jobsActive;
            } else if (type == 'events') {
               this.eventsActive = !this.eventsActive;
            } else if (type == 'abellio') {
               this.abellioActive = !this.abellioActive;
            }

         },
         disableAllFilters() {
            this.linesActive = false;
            this.stationsActive = false;
            //this.ticketMachinesActive = false;
            this.salesPointsActive = false;
            // this.jobsActive = false;
            this.eventsActive = false;
            this.abellioActive = false;
         },
         searchLocation() {
            if (this.zipCode != '') {
               const geocoder = new this.google.maps.Geocoder();
               geocoder.geocode({'address': this.zipCode + ',DE,Germany'}, (results, status) => {
                  if (
                     status === 'OK' && results.length
                  ) {
                     this.saveSearchedLocationOnLocalStorage(this.zipCode, results[0].geometry.location);
                     this.$refs.mapRef.$mapObject.setCenter(results[0].geometry.location);
                     this.changeCurrentMarkerLocation(results[0].geometry.location);
                     if (results[0].geometry.bounds) {
                        this.saveSearchedLocationOnLocalStorage(this.zipCode, results[0].geometry.location, results[0].geometry.bounds);
                        this.$refs.mapRef.$mapObject.fitBounds(results[0].geometry.bounds);
                     } else {
                        this.$refs.mapRef.$mapObject.setZoom(12);
                     }
                  } else {
                     this.$store.dispatch('alert/error', {message: 'Location with this ZIP Code has been not found!'});
                  }
               });
            }
         },
         boundFilteredMarkers() {
            this.contentID = null;
            this.contentType = null;
            this.showAbellioWorldMobileContent = false;

            this.filteredLines = [];
            if (this.linesActive && this.content.with_lines == '1') {
               this.line.all.forEach(item => {
                  if (
                     this.$refs.mapRef.$mapObject.getBounds().contains({
                        lat: parseFloat(item.latitude),
                        lng: parseFloat(item.longitude)
                     })
                  ) {
                     let itemContent = {};
                     itemContent.id = item.id;
                     itemContent.slug = item.slug;
                     itemContent.image_thumb = item.image;
                     itemContent.image = item.image;
                     itemContent.title = item.title;
                     itemContent.color = item.color;
                     itemContent.short_content = 'Train: ' + item.train.title + '<br/>Distance: ' + item.distance + ' km';

                     this.filteredLines.push(itemContent);
                  }

               });
            }

            this.filteredStations = [];
            if (this.stationsActive && this.content.with_stations == '1') {
               this.station.all.forEach(item => {
                  if (
                     this.$refs.mapRef.$mapObject.getBounds().contains({
                        lat: parseFloat(item.latitude),
                        lng: parseFloat(item.longitude)
                     })
                  ) {
                     let itemContent = {};
                     itemContent.id = item.id;
                     itemContent.slug = item.slug;
                     itemContent.image_thumb = item.image;
                     itemContent.image = item.image;
                     itemContent.title = item.title;
                     itemContent.short_content = item.street + ' ' + item.zip + ' ' + item.city.title;
                     itemContent.full = item;

                     this.filteredStations.push(itemContent);
                  }

               });
            }

            this.filteredSalesPoints = [];
            if (this.salesPointsActive && this.content.with_sales_points == '1') {
               this.ticketSalesPoint.all.forEach(item => {
                  if (
                     this.$refs.mapRef.$mapObject.getBounds().contains({
                        lat: parseFloat(item.latitude),
                        lng: parseFloat(item.longitude)
                     })
                  ) {
                     let itemContent = {};
                     itemContent.id = item.id;
                     itemContent.slug = item.slug;
                     itemContent.image_thumb = item.image;
                     itemContent.image = item.image;
                     itemContent.title = item.title;
                     itemContent.short_content = item.street + ' ' + item.zip + ' ' + item.city.title;
                     itemContent.item = item;

                     this.filteredSalesPoints.push(itemContent);
                  }

               });
            }
            this.filteredEvents = [];
            if (this.eventsActive && this.content.with_events == '1') {
               this.events.all.forEach(item => {
                  if (
                     this.$refs.mapRef.$mapObject.getBounds().contains({
                        lat: parseFloat(item.latitude),
                        lng: parseFloat(item.longitude)
                     })
                  ) {
                     let itemContent = {};
                     itemContent.id = item.id;
                     itemContent.slug = item.slug;
                     itemContent.image_thumb = item.image;
                     itemContent.image = item.image;
                     itemContent.title = item.title;
                     itemContent.short_content = item.street + ' ' + item.zip + ' ' + item.city.title;

                     this.filteredEvents.push(itemContent);
                  }

               });
            }

            this.filteredAbellioOffices = [];
            if (this.abellioActive && this.content.with_abellio == '1') {
               this.office.all.forEach(item => {
                  if (
                     this.$refs.mapRef.$mapObject.getBounds().contains({
                        lat: parseFloat(item.latitude),
                        lng: parseFloat(item.longitude)
                     })
                  ) {
                     let itemContent = {};
                     itemContent.id = item.id;
                     itemContent.slug = item.slug;
                     itemContent.image_thumb = item.image;
                     itemContent.image = item.image;
                     itemContent.title = item.title;
                     itemContent.short_content = item.street + ' ' + item.zip + ' ' + item.city.title;

                     this.filteredAbellioOffices.push(itemContent);
                  }

               });
            }
         },
         markerClicked(location, _self) {
            this.showAbellioWorldMobileContent = this.abellioWorldMobileFilter = false;

            if (this.$refs.linesRef && this.$refs.linesRef !== _self) {
               this.$refs.linesRef.infoWindowOpen = false;
            }
            if (this.$refs.stationsRef && this.$refs.stationsRef !== _self) {
               this.$refs.stationsRef.infoWindowOpen = false;
            }
            if (this.$refs.salesPointsRef && this.$refs.salesPointsRef !== _self) {
               this.$refs.salesPointsRef.infoWindowOpen = false;
            }
            // if (this.$refs.jobsRef && this.$refs.jobsRef !== _self) {
            //     this.$refs.jobsRef.infoWindowOpen = false;
            // }
            if (this.$refs.eventsRef && this.$refs.eventsRef !== _self) {
               this.$refs.eventsRef.infoWindowOpen = false;
            }
            if (this.$refs.abellioOfficeRef && this.$refs.abellioOfficeRef !== _self) {
               this.$refs.abellioOfficeRef.infoWindowOpen = false;
            }

            this.$refs.mapRef.$mapObject.setCenter(location);
            this.$refs.mapRef.$mapObject.setZoom(14);
         },
         showContent(item, type) {
            this.contentID = item.id;
            this.contentType = type;

            if (!this.isMobile) {
               const filterWrapperElement = $(this.$el).find('.abellio_world_filter_wrapper');
               $('html, body').stop(true, false).animate({
                  scrollTop: filterWrapperElement.offset().top + filterWrapperElement.outerHeight()
               }, 800);
            } else {
               this.abellioWorldMobileFilter = false;
               this.showAbellioWorldMobileContent = true;
            }
         },
         changeCurrentMarkerLocation(location) {
            this.zipCodeFound = true;
            this.currentMarkerLocation = location;
            if (this.$refs.currentLocationMarker != undefined) {
               this.$refs.currentLocationMarker.$markerObject.setAnimation(1)
               let self = this;
               setTimeout(function () {
                  self.$refs.currentLocationMarker.$markerObject.setAnimation(0)
               }, 2000);
            } else {
               let self = this;
               setTimeout(function () {
                  self.$refs.currentLocationMarker.$markerObject.setAnimation(1)
                  setTimeout(function () {
                     self.$refs.currentLocationMarker.$markerObject.setAnimation(0)
                  }, 2000);
               }, 2000);
            }
         },
         saveSearchedLocationOnLocalStorage(zipCode, location, bounds = null) {
            localStorage.setItem('abwd', JSON.stringify({
               zipCode: zipCode,
               bounds: bounds,
               zoom: 12,
               location: location,
            }));
         },
         loadPreviousLocationSearched() {
            let previousData = localStorage.getItem('abwd');
            if (previousData != null) {
               previousData = JSON.parse(previousData);
               this.zipCode = previousData.zipCode;
               this.zipCodeFound = true;
            }

            if (previousData != null) {
               this.changeCurrentMarkerLocation(previousData.location);
               this.$refs.mapRef.$mapObject.setCenter(previousData.location);
               if (previousData.bounds != null) {
                  this.$refs.mapRef.$mapObject.fitBounds(previousData.bounds);
               } else {
                  this.$refs.mapRef.$mapObject.setZoom(previousData.zoom);
               }
            }
         },
         loadData() {
            this.$store.dispatch('line/getAll');
            this.$store.dispatch('station/getAll');
            // this.$store.dispatch('job/getAll');
            this.$store.dispatch('events/getAll');
            this.$store.dispatch('ticketSalesPoint/getAll');
            this.$store.dispatch('office/getAll');
         }
      },
      beforeMount() {
         this.mapOptions.styles = this.settings.all.map_style;
      },
      mounted() {
         this.carouselWrapperWidth();
         window.addEventListener('resize', this.carouselWrapperWidth);

         this.$gmapApiPromiseLazy().then(() => {
            this.currentLocation.lat = parseFloat(this.region.currentRegion.latitude);
            this.currentLocation.lng = parseFloat(this.region.currentRegion.longitude);
            this.mapZoom = parseFloat(this.region.currentRegion.map_zoom);

            this.loadPreviousLocationSearched();
         });

         $(document).on('mouseenter', '.category-marker', function () {
            $(this).addClass('is-active');
         })

         $(document).on('mouseleave', '.category-marker', function () {
            $(this).removeClass('is-active');
         })
      },
      created() {
         this.loadData()
         this.currentRegion = this.region.currentRegion.id;
      },
      watch: {
         'isContentVisible': function (val) {
            if (val) {
               this.$nextTick(() => {
                  this.carouselWrapperWidth();
                  this.loadPreviousLocationSearched()
                  this.loadData()
               });
            }
         },
         'currentRegion': function (val) {
            this.$store.commit('region/setCurrentRegion', this.region.all.find(region => region.id == val));
         },
         'region.currentRegion': function (val) {
            this.$gmapApiPromiseLazy().then(() => {
               this.currentLocation.lat = parseFloat(val.latitude);
               this.currentLocation.lng = parseFloat(val.longitude);
               this.mapZoom = parseFloat(val.map_zoom);
               this.loadData()
            });
         },
         'abellioWorldMobileFilter': function () {
         }
      }
   }
</script>

<style lang="scss">

   .category-marker {
      position: relative;
      width: 30px;
      height: 30px;
      background-color: rgb(84, 155, 201);
      border-radius: 50%;
      box-shadow: inset 0 0 10px 0px rgba(0, 0, 0, 0.30);

      &.sales-only {
         background-color: #9ccbe0;
      }

      &.station-only {
         background-color: #0071bc;
      }

      .counter {
         position: absolute;
         left: 0;
         right: 0;
         top: 0;
         bottom: 0;
         margin: auto;
         font-size: 10px;
         pointer-events: none;
      }

      &.is-active {
         .category-marker__inner {
            visibility: visible;
            opacity: 1;
         }
      }

      &__inner {
         visibility: hidden;
         opacity: 0;
         width: 30px;
         height: 30px;
         transition: all .3s linear;
         border-radius: 50%;
         overflow: hidden;
         position: relative;
         left: 0;
         right: 0;

         img {
            position: absolute;
            left: 0;
            top: 0;
         }

         &[data-type="0"] {
            background-color: #9ccbe0;
         }

         &[data-type="1"] {
            background-color: #0071bc;
         }

         &:nth-of-type(1) {
            left: -22.5px;
            top: -30px;
         }

         &:nth-of-type(2) {
            left: 20px;
         }
      }
   }

   .cluster > img {
      display: none;
   }
</style>
<style lang="scss" scoped>
   .job_offers_map_wrapper {
      background: #333333;
   }

   .job_offers_map_wrapper .vue-map {
      opacity: 0;
   }

   .job_offers_map_wrapper.is_visible .vue-map {
      opacity: 1;
   }
</style>
