<template>
   <div class="content_element title_big_image_element">
      <div class="container">
         <div class="row">
            <div class="col-12 col-sm-12 col-md-3 col-lg-3">
               <nl2br tag="h2" :text="content.title" v-if="content.title"/>
            </div>
            <div class="col-12 col-sm-12 col-md-9 col-lg-9">
               <div class="title_big_image_wrapper">
                  <img v-if="content.image" :src="cmsAsset(content.image)" alt="">
                  <div class="copyright_sign" v-if="content.copyright">
                     <p>{{ content.copyright }}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import $ from 'jquery';

   export default {
      name: "TitleBigImage",
      props: {
         content: {
            type: Object,
            required: true
         }
      },
      methods: {
         wrapperWidth: function () {
            let element = $(this.$el).find('.title_big_image_wrapper');
            let offsetLeft = element.offset().left;
            element.outerWidth($(this.$el).outerWidth() - offsetLeft);
         }
      },
      mounted() {
         this.wrapperWidth();
         window.addEventListener('resize', this.wrapperWidth);
      }
   }
</script>

<style scoped>

</style>
