<template>
   <div class="content_element carousel_content_element">
      <div class="container">
         <div class="row">
            <div class="col-12 col-sm-12 col-md-3 col-lg-2">
               <nl2br tag="h2" :text="content.title"/>
            </div>
            <div class="col-12 col-sm-12 col-md-9 col-lg-10">
               <div class="carousel_content_wrapper">
                  <swiper
                     class="swiper"
                     ref="swiperCarousel"
                     :options="swiperOption"
                     :auto-update="true"
                     :auto-destroy="true"
                     v-if="content.items && content.items.length"
                  >
                     <swiper-slide
                        v-for="(item, index) in content.items"
                        :key="index + 'AWcarouselContent'"
                     >
                        <div
                           class="abellio_info_box"
                           :class="{ is_active: activeContentID == item.id }"
                        >
                           <div class="abellio_info_box_image"
                                :style="{backgroundImage: 'url(' + cmsAssetOrPlaceholder(item.image_thumb) + ')'}">
                           </div>
                           <div class="abellio_info_box_content">
                              <h6
                                 class="abellio_info_box_title"
                                 :style="'color:' + item.color"
                              >
                                 {{ item.title }}
                              </h6>
                              <p v-html="item.short_content"></p>
                           </div>
                           <a
                              href="#"
                              @click.prevent="openContent(item.id)"
                              class="abellio_info_box_more"
                           >Mehr erfahren</a
                           >
                        </div>
                     </swiper-slide>
                     <div class="swiper-pagination" slot="pagination"></div>
                  </swiper>
               </div>
            </div>
         </div>
      </div>
      <div
         class="carousel_big_content_wrapper"
         v-if="selectedContent && selectedContent.title"
      >
         <div class="container">
            <div class="row">
               <div class="col-12 col-sm-12 col-lg-6">
                  <img
                     class="carousel_big_image"
                     :src="cmsAssetOrPlaceholder(selectedContent.image)"
                     :alt="selectedContent.title"/>
               </div>
               <div
                  class="col-12 col-sm-12 col-lg-6 d-flex flex-column justify-content-between"
               >
                  <div>
                     <h2 :style="'color:' + selectedContent.color">
                        {{ selectedContent.title }}
                     </h2>
                     <div class="html-content">
                        <ul v-if="features.length">
                           <li v-for="(feature, index) in features" :key="index">
                              {{ feature }}
                           </li>
                        </ul>
                        <p v-if="selectedContent.full.features.db_information">
                           DB Information:
                           {{ selectedContent.full.features.db_information }}
                        </p>
                        <p v-if="selectedContent.full.features.mobile_service_db">
                           mobiler Service DB:
                           {{ selectedContent.full.features.mobile_service_db }}
                        </p>
                     </div>
                  </div>
                  <div>
                     <router-link
                        class="info_tag_btn more_btn d-inline-flex justify-content-between"
                        :to="{ name: 'station', params: {slug: selectedContent.slug, locale: localeRouteParams}}">
                        <span class="color"></span>
                        {{ selectedContent.full.title }}
                        <span class="info_tag_arrow d-flex align-items-center"
                        >lesen</span
                        >
                     </router-link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import $ from "jquery";
   import {mapState} from "vuex";
   import {Swiper, SwiperSlide} from "vue-awesome-swiper";

   export default {
      name: "AbellioWorldCarouselStationsContent",
      components: {
         Swiper,
         SwiperSlide,
      },
      props: {
         content: {
            type: Object,
            required: true,
         },
         id: {
            type: Number,
            required: true,
         },
      },
      data() {
         return {
            swiperOption: {
               slidesPerView: 1.5,
               spaceBetween: 16,
               breakpoints: {
                  // when window width is >= 320px
                  375: {
                     slidesPerView: 1,
                     spaceBetween: 16,
                  },
                  414: {
                     slidesPerView: 1,
                     spaceBetween: 16,
                  },
                  576: {
                     slidesPerView: 2.2,
                     spaceBetween: 16,
                  },
                  768: {
                     slidesPerView: 2.5,
                     spaceBetween: 16,
                  },
                  992: {
                     slidesPerView: 3.5,
                     spaceBetween: 16,
                  },
                  1280: {
                     slidesPerView: 3.5,
                     spaceBetween: 16,
                  },
               },
               pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
               },
            },
            activeContentID: null,
         };
      },
      computed: {
         ...mapState(["locales"]),
         localeRouteParams() {
            return (this.locales.currentLocale !== this.locales.defaultLocale) ? this.locales.currentLocale : null
         },
         selectedContent: function () {
            return this.content.items.find((item) => {
               return item.id == this.activeContentID;
            });
         },
         features() {
            let features = [];

            for (const [key, value] of Object.entries(
               this.selectedContent.full.features
            )) {
               if (value == "1") {
                  features.push(this.getTranslation(key));
               }
            }

            return features;
         },
      },
      methods: {
         getTranslation(key) {
            let translations = {
               db_information: "DB Information",
               mobile_service_db: "mobiler Service DB",
               control_center: "3-S-Zentrale",
               elevator: "Aufzug",
               rent_car: "Autovermietung",
               station_mission: "Bahnhofsmission",
               step_free_access: "stufenfreier Zugang",
               partial_step_free_access: "teilw.stufenfreier Zugang",
               bikesharing: "Bikesharing",
               mailbox: "Briefkasten",
               police: "Bundespolizei",
               car_sharing: "Carsharing",
               db_lounge: "DB Lounge",
               travel_center: "Reisezentrum/Schalter",
               atm: "ec-Geldautomat",
               ticket_sales: "Fahrkartenverkauf",
               mobility_service_db: "Mobilitätsservice DB",
               car_parking: "Parkplätze",
               bicycle_parking: "Fahrradstellplätze",
               bike_station: "Radstation",
               taxi: "Taxi",
               lockers: "Schließfächer",
               waiting_area: "Wartebereich",
               wc: "WC",
               wlan: "WLAN",
               administration: "Verwaltung",
            };

            return translations[key];
         },
         carouselWrapperWidth: function () {
            let element = $(this.$el).find(".carousel_content_wrapper");
            let offsetLeft = element.offset().left;
            element.outerWidth($(this.$el).outerWidth() - offsetLeft);

            this.$nextTick(() => {
               if (this.$refs.swiperCarousel) {
                  this.$refs.swiperCarousel.$swiper.update();
               }
            });
         },
         openContent(id) {
            this.activeContentID = id;
            this.toSlide(id);
         },
         toSlide(id) {
            let index = 0;
            for (let i = 0; i < this.content.items.length; i++) {
               if (this.content.items[i].id == id) {
                  index = i;
                  break;
               }
            }
            this.$refs.swiperCarousel.$swiper.slideTo(index, 0);
         },
      },
      mounted() {
         this.carouselWrapperWidth();
         window.addEventListener("resize", this.carouselWrapperWidth);
      }
   }
</script>

<style scoped>
   .swiper-slide {
      height: auto;
      padding: 5px 0 15px 0;
   }
</style>
