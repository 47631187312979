<template>
    <div class="content_element accordion_element">
        <div class="container">

            <div class="row" v-if="content.title">
                <div class="col-12 mb10">
                    <h3>{{ content.title }}</h3>
                </div>
            </div>

            <div class="accordion_wrapper">
                <div class="accordion_item" :class="{ 'is_open' : activeAccordions.includes(row1Index) }"
                     v-for="(item, row1Index) in content.rows" :key="row1Index" :tabindex="row1Index">

                    <h6 class="accordion_title mb0" @click="toggleAccordion(row1Index)">{{item.title}}</h6>
                    <transition name="slide">
                        <div class="accordion_content html-content" v-if="activeAccordions.includes(row1Index)">
                            <div class="tabs_section">
                                <ul class="tabs_navigation">
                                    <li v-for="(tab, tabIndex) in item.rows"
                                        :class="{ active: activeTabs[row1Index] === tabIndex }"
                                        :key="'tab-' + row1Index + tabIndex">
                                        <a @click="toggleTab(row1Index,tabIndex)">{{ tab.tab }}</a>
                                    </li>
                                </ul>
                                <div class="content_tabs_wrap">
                                    <template v-for="(tab, tabIndex) in item.rows">
                                        <div class="tabs_content" v-if="activeTabs[row1Index] === tabIndex"
                                            :key="'tab-content-' + row1Index + tabIndex" v-html="tab.content">
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AccordionWithTabs",
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                activeTabs: [],
                activeAccordions: []
            }
        },
        methods: {
            toggleAccordion(id) {
                if (this.activeAccordions.includes(id)) {
                    this.activeAccordions = this.activeAccordions.filter(i => i !== id);
                    return;
                }

                this.activeAccordions.push(id);
            },
            toggleTab(rowId, tabId) {
                this.activeTabs[rowId] = tabId;
            },

        },
        mounted() {
            let activeTabs = [];
            this.content.rows.forEach((index, key) => {
                activeTabs[key] = 0;
            });

            this.activeTabs = Object.assign({}, this.activeTabs, activeTabs);
        }
    }
</script>
