<template>
    <div class="content_element jobs_carousel_element">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                    <nl2br tag="h2" :text="content.title"/>
                </div>
                <div class="col-12 col-sm-12 col-md-9 col-lg-10">
                    <div class="carousel_content_wrapper">
                        <swiper class="swiper"
                                ref="swiperCarousel"
                                :options="swiperOption"
                                :auto-update="true"
                                :auto-destroy="true"
                                v-if="job.latest && job.latest.length"
                        >
                            <swiper-slide v-for="(item, index) in job.latest" :key="index+'carouselContent'" class="job-item-slide">
                                <div class="abellio_info_box job_info_box">
                                    <div class="abellio_info_box_image alt" :style="{backgroundImage: 'url('  + getBackgoundImage(item) + ')'}">
                                    </div>
                                    <div class="abellio_info_box_content abellio_info_holder">
                                       <div class="abellio_info_box_holder">
                                            <div class="share-popup" :id="'share-popup-' + item.id">
                                            <button class="close-share-popup"
                                                    @click.prevent="toggleSharePopup(item.id)">
                                                <img
                                                        src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23000000%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E%0A">
                                            </button>

                                            <a :href="'mailto:?to=&body=Stellenangebote:' + item.title + ' ' + item.id +' URL: ' + getUrl(item) + '&subject=Stelleangebote'">
                                                <i class="icon">
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                         viewBox="0 0 1000 1000"
                                                         enable-background="new 0 0 1000 1000" xml:space="preserve">
                                <g><g transform="matrix(1 0 0 -1 0 1008)"><path d="M71.3,201.8h857.5V622L532.3,374.4h-64.7L71.3,622V201.8z M500,426.3l428.8,268v119.9H71.3V694.3L500,426.3z M990,875.5v-735H10v735H990z"/></g></g>
                            </svg>
                                                </i>
                                                Send via email
                                            </a>
                                            <a class="cursor-pointer" v-clipboard.prevent="getUrl(item)"
                                               v-clipboard:success="urlCopied">
                                                <i class="icon">
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                         viewBox="0 0 1000 1000"
                                                         enable-background="new 0 0 1000 1000" xml:space="preserve">
                                <g><path d="M76.5,78.6h664.4v77.6h66.5V12H10v771.1h116.4v-66.5H76.5V78.6z"/>
                                    <path d="M192.6,216.9V988H990V216.9H192.6z M923.5,921.4H259.1V283.4h664.3V921.4z"/></g>
</svg>
                                                </i> Copy URL
                                            </a>
                                        </div>
                                        <p class="abellio_info_box_sub_title" v-if="item.type.title">
                                            {{item.job_type.title}}</p>
                                        <h6 class="abellio_info_box_title">{{item.title}}</h6>
                                        <p class="abellio_info_box_address">
                                            <i class="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14.385"
                                                     viewBox="0 0 10 14.385">
                                                    <path id="Shape"
                                                          d="M5,14.385H5C4.954,14.324,0,8.406,0,5A5,5,0,1,1,10,5c0,3.237-4.944,9.322-4.994,9.383ZM5,2.822a2.133,2.133,0,1,0,1.508.625A2.121,2.121,0,0,0,5,2.822Z"
                                                          transform="translate(0)" fill="#ffffff"/>
                                                </svg>
                                            </i>
                                            {{ item.address }}, {{ item.zip }} {{ item.city.title }}
                                        </p>
                                        <div class="d-flex justify-content-between align-items-start mt-2">
                                            <a class="job_info_box_apply_button" href="#"
                                               @click.prevent="applyForJob(item)">
                                                <i class="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                         viewBox="0 0 14 14">
                                                        <path id="Combined_Shape" data-name="Combined Shape"
                                                              d="M1.5,14A1.5,1.5,0,0,1,0,12.5V2.5A1.5,1.5,0,0,1,1.5,1h6a.5.5,0,1,1,0,1h-6a.5.5,0,0,0-.5.5v10a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5v-5a.5.5,0,0,1,1,0v5A1.5,1.5,0,0,1,10.5,14Zm1.842-3.026a.5.5,0,0,1-.317-.633l1-3a.516.516,0,0,1,.121-.2L10.733.561A1.914,1.914,0,1,1,13.44,3.268L6.854,9.853a.508.508,0,0,1-.2.122l-3,1a.5.5,0,0,1-.315,0Z"
                                                              transform="translate(0 0)" fill="#ffffff"/>
                                                    </svg>
                                                </i>
                                                Jetzt Bewerben
                                            </a>
                                            <div class="job_info_box_actions">
                                                <router-link :to="{name: 'rate-calculator'}"
                                                             class="job_info_box_action_button">
                                                    <strong class="euro-sign">€</strong>
                                                </router-link>
                                                <a href="#" class="job_info_box_action_button"
                                                   @click.prevent="toggleSharePopup(item.id)">
                                                    <i class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.833"
                                                             height="14"
                                                             viewBox="0 0 12.833 14">
                                                            <path id="Combined_Shape" data-name="Combined Shape"
                                                                  d="M8.167,11.667a2.331,2.331,0,0,1,.066-.554L3.956,8.675a2.333,2.333,0,1,1,0-3.35L8.233,2.887a2.339,2.339,0,1,1,.553,1.027L4.566,6.321a2.338,2.338,0,0,1,0,1.359l4.22,2.406a2.332,2.332,0,1,1-.62,1.582Z"
                                                                  fill="#ffffff"/>
                                                        </svg>
                                                    </i>
                                                </a>
                                            </div>
                                        </div>
                                       </div>
                                        <div class="view-more">
                                            <router-link :to="{name: 'job', params: {slug: item.slug}}"
                                                         class="job_info_box_action_button view-more">
                                                <i class="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24">
                                                        <path fill="#CE132D"
                                                              d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z"/>
                                                    </svg>
                                                </i>
                                                Mehr
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import {mapState} from 'vuex';
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

    export default {
        name: "JobsCarousel",
        components: {
            Swiper,
            SwiperSlide
        },
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                swiperOption: {
                    slidesPerView: 1.5,
                    spaceBetween: 16,
                    draggable: true,
                    breakpoints: {
                        // when window width is >= 320px
                        576: {
                            slidesPerView: 1,
                            spaceBetween: 16
                        },
                        768: {
                            slidesPerView: 2.5,
                            spaceBetween: 16
                        },
                        992: {
                            slidesPerView: 3.5,
                            spaceBetween: 16
                        },
                        1280: {
                            slidesPerView: 3.5,
                            spaceBetween: 16
                        }
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    }
                }
            }
        },
        computed: {
            ...mapState([
                'job',
                'jobPortal',
            ])
        },
        methods: {
            getBackgoundImage(job){
                if(job.image){
                    return job.image;
                }

                return this.cmsAsset('job-images/default-placeholder.png');
            },
            carouselWrapperWidth: function () {
                let element = $(this.$el).find('.carousel_content_wrapper');
                let offsetLeft = element.offset().left;
                element.outerWidth($(this.$el).outerWidth() - offsetLeft);

                this.$nextTick(() => {
                    if (this.$refs.swiperCarousel) {
                        this.$refs.swiperCarousel.$swiper.update();
                    }
                });
            },
            applyForJob(job) {
                this.$confirm(
                    {
                        message: `Do you want to use actual data?`,
                        button: {
                            yes: 'Ja',
                            no: 'Nein'
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.$store.dispatch('jobPortal/applyForJob', job.id);
                            } else {
                                this.$router.push({
                                    name: 'applications-wizard'
                                });
                            }
                        }
                    }
                )

                // if (this.jobPortal.applicantData.profile === null) {
                //     let self = this;
                //     this.$store.dispatch('jobPortal/loadApplicantData').then(() => {
                //         self.applyForJob(job);
                //     })
                // } else {
                //     if (this.jobPortal.applicantData.characteristics.length == 0) {
                //         this.$store.dispatch('alert/success', {
                //             title: 'Info',
                //             message: 'You have to fill your information before you can apply for a new Job'
                //         })
                //         this.$router.push({
                //             name: 'applications-job-navigator'
                //         });
                //     } else {
                //         this.$store.dispatch('jobPortal/applyForJob', job.id);
                //     }
                // }
            },
            toggleSharePopup(id) {
                document.getElementById('share-popup-' + id) && document.getElementById('share-popup-' + id).classList.toggle('visible');
            },
            getUrl(job) {
                return window.location.origin + this.$router.resolve({name: 'job', params: {slug: job.slug}}).href
            },
            urlCopied() {
                this.$store.dispatch('alert/success', {
                    title: 'Succes',
                    message: 'Url copied succesfully'
                })
            }
        },
        created() {
            this.$store.dispatch('job/getLatest', {
                sort_by: this.content.sort_by,
                order: this.content.order,
                limit: this.content.limit
            });
        },
        mounted() {
            this.carouselWrapperWidth();
            window.addEventListener('resize', this.carouselWrapperWidth);
        }
    }
</script>

<style lang="scss" scoped>
    .share-popup {
        width: 95%;
    }
</style>
