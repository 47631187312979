<template>
   <div class="timetable_element timetable_inline" :class="{ is_open: globalPopup.isTimetableOpen }">
      <div class="timetable_wrapper">
         <div class="timetable_inner_wrapper container">
            <div class="timetable_inner_inner">
               <div class="timetable_controls_wrapper">
                  <div class="timetable_controls_line" :class="{active: timetableFromSelected}">
                     <span class="timetable_controls_line_icon">
                        <span class="colored-dot-icon white mt5"></span>
                     </span>
                     <span class="timetable_controls_line_icon line_icon_end">
                        <span class="colored-dot-icon white mt5"></span>
                     </span>
                  </div>

                  <div class="timetable_inputs_wrapper">
                     <div class="timetable_input_wrapper">
                        <label for="timetable_from">{{ __t('from') }}</label>
                        <div class="timetable_input_autocomplete_wrapper timetable_from_wrapper"
                             :class="{ z0: timetableToActive }">
                             <span class="dest-icon">A</span>
                           <input
                              id="timetable_from"
                              type="text"
                              :placeholder="__t('timetable.placeholder')"
                              v-model="timetableFrom"
                              autocomplete="off"
                              @focus="timetableFromActive = true; timetableFrom = ''"/>
                           <span v-if="timetableFromLoading" class="loading-icon">
                                 <svg viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
                                       <circle cx="22" cy="22" r="6" stroke-opacity="0">
                                          <animate attributeName="r"
                                                   begin="1.5s" dur="3s"
                                                   values="6;22"
                                                   calcMode="linear"
                                                   repeatCount="indefinite"/>
                                          <animate attributeName="stroke-opacity"
                                                   begin="1.5s" dur="3s"
                                                   values="1;0" calcMode="linear"
                                                   repeatCount="indefinite"/>
                                          <animate attributeName="stroke-width"
                                                   begin="1.5s" dur="3s"
                                                   values="2;0" calcMode="linear"
                                                   repeatCount="indefinite"/>
                                       </circle>
                                       <circle cx="22" cy="22" r="6" stroke-opacity="0">
                                          <animate attributeName="r"
                                                   begin="3s" dur="3s"
                                                   values="6;22"
                                                   calcMode="linear"
                                                   repeatCount="indefinite"/>
                                          <animate attributeName="stroke-opacity"
                                                   begin="3s" dur="3s"
                                                   values="1;0" calcMode="linear"
                                                   repeatCount="indefinite"/>
                                          <animate attributeName="stroke-width"
                                                   begin="3s" dur="3s"
                                                   values="2;0" calcMode="linear"
                                                   repeatCount="indefinite"/>
                                       </circle>
                                       <circle cx="22" cy="22" r="8">
                                          <animate attributeName="r"
                                                   begin="0s" dur="1.5s"
                                                   values="6;1;2;3;4;5;6"
                                                   calcMode="linear"
                                                   repeatCount="indefinite"/>
                                       </circle>
                                    </g>
                                 </svg>
                              </span>
                           <div class="timetable_input_autocomplete" :class="{ is_open: timetableFromActive }">
                              <div class="timetable_input_autocomplete_inner">
                                 <template v-if="timetableFrom">
                                    <a href="#" class="timetable_input_autocomplete_item"
                                       v-for="(place, index) in autoSuggestPlaces.places"
                                       :key="'fromas-' + index"
                                       @click.prevent="selectFromTimetableFrom(place)">
                                       <span class="icon"><i class="ab-icon-headquarters"></i></span>
                                       {{ place.name }}
                                    </a>
                                 </template>
                                 <template v-else>
                                    <a href="#" class="timetable_input_autocomplete_item"
                                       :class="{location_home: place.type === 1 || place.type === 2, location_work: place.type === 3 || place.type === 4}"
                                       v-for="(place, index) in savedPlaces"
                                       :key="'fromsvp-' + index"
                                       @click.prevent="selectFromTimetableFrom({id:place.id, name: place.station})">
                                    <span class="icon">
                                      <i v-if="place.type === 1" class="ab-icon-home"></i>
                                      <i v-if="place.type === 2" class="ab-icon-job"></i>
                                      <i v-if="place.type === 3" class="ab-icon-train"></i>
                                      <i v-if="place.type === 4" class="ab-icon-events"></i>
                                    </span>
                                       {{ place.name }}
                                    </a>
                                    <a href="#" class="timetable_input_autocomplete_item"
                                       v-for="(place, index) in selectedPlaces"
                                       :key="'fromsp-' + index"
                                       @click.prevent="selectFromTimetableFrom(place)">
                                       <span class="icon"><i class="ab-icon-headquarters"></i></span>
                                       {{ place.name }}
                                    </a>
                                 </template>
                              </div>
                           </div>
                        </div>
                     </div>
                     <!-- <div :style="!timetableFromSelected ? 'opacity: 0.5;pointer-events:none' : ''"
                          class="timetable_switch_button_wrapper"
                          :class="{ z0: timetableFromActive }">
                        <button class="timetable_switch_button">
                           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                              <g id="Icon_Medium_Richtungswechsel_Black_Copy_2"
                                 data-name="Icon/Medium/Richtungswechsel/Black Copy 2"
                                 transform="translate(0 40) rotate(-90)">
                                 <g id="Group" transform="translate(9 8)">
                                    <path
                                       id="Fill_1"
                                       data-name="Fill 1"
                                       d="M6.5,0,0,6.15V8.2L5.749,2.765v16.58h1.5V2.765L13,8.195V6.15Z"
                                       transform="translate(10 0)"
                                       fill="#000"
                                    />
                                    <path
                                       id="Fill_1_Copy"
                                       data-name="Fill 1 Copy"
                                       d="M6.5,0,0,6.15V8.2L5.749,2.765v16.58h1.5V2.765L13,8.195V6.15Z"
                                       transform="translate(13 25.345) rotate(-180)"
                                       fill="#000"/>
                                 </g>
                              </g>
                           </svg>
                        </button>
                     </div> -->
                     <div class="timetable_input_wrapper">
                           <label for="timetable_to">{{ __t('to') }}</label>
                        <div class="timetable_input_autocomplete_wrapper timetable_to_wrapper"
                             :class="{ z0: timetableFromActive }">
                             <span class="dest-icon">B</span>
                           <input
                              id="timetable_to"
                              type="text"
                              :placeholder="__t('timetable.placeholder')"
                              v-model="timetableTo"
                              autocomplete="off"
                              @focus="timetableToActive = true; timetableTo = ''"
                           />
                            <button @click="switchRoutes"  :style="!timetableFromSelected ? 'opacity: 0;pointer-events:none' : ''" class="input-arrows">
                               <img src="../../assets/images/arrows.svg" alt="Abellio" />
                            </button>
                           <span v-if="timetableToLoading" class="loading-icon">
                                 <svg viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
                                       <circle cx="22" cy="22" r="6" stroke-opacity="0">
                                          <animate attributeName="r"
                                                   begin="1.5s" dur="3s"
                                                   values="6;22"
                                                   calcMode="linear"
                                                   repeatCount="indefinite"/>
                                          <animate attributeName="stroke-opacity"
                                                   begin="1.5s" dur="3s"
                                                   values="1;0" calcMode="linear"
                                                   repeatCount="indefinite"/>
                                          <animate attributeName="stroke-width"
                                                   begin="1.5s" dur="3s"
                                                   values="2;0" calcMode="linear"
                                                   repeatCount="indefinite"/>
                                       </circle>
                                       <circle cx="22" cy="22" r="6" stroke-opacity="0">
                                          <animate attributeName="r"
                                                   begin="3s" dur="3s"
                                                   values="6;22"
                                                   calcMode="linear"
                                                   repeatCount="indefinite"/>
                                          <animate attributeName="stroke-opacity"
                                                   begin="3s" dur="3s"
                                                   values="1;0" calcMode="linear"
                                                   repeatCount="indefinite"/>
                                          <animate attributeName="stroke-width"
                                                   begin="3s" dur="3s"
                                                   values="2;0" calcMode="linear"
                                                   repeatCount="indefinite"/>
                                       </circle>
                                       <circle cx="22" cy="22" r="8">
                                          <animate attributeName="r"
                                                   begin="0s" dur="1.5s"
                                                   values="6;1;2;3;4;5;6"
                                                   calcMode="linear"
                                                   repeatCount="indefinite"/>
                                       </circle>
                                    </g>
                                 </svg>
                           </span>
                           <div class="timetable_input_autocomplete" :class="{ is_open: timetableToActive }">
                              <div class="timetable_input_autocomplete_inner">
                                 <template v-if="timetableTo">
                                    <a href="#" class="timetable_input_autocomplete_item"
                                       v-for="(place, index) in autoSuggestPlaces.places"
                                       :key="'toas-' + index"
                                       @click.prevent="selectFromTimetableTo(place)">
                                       <span class="icon"><i class="ab-icon-headquarters"></i></span>
                                       {{ place.name }}
                                    </a>
                                 </template>
                                 <template v-else>
                                    <a href="#" class="timetable_input_autocomplete_item"
                                       :class="{location_home: place.type === 1 || place.type === 2, location_work: place.type === 3 || place.type === 4}"
                                       v-for="(place, index) in savedPlaces"
                                       :key="'tosvp-' + index"
                                       @click.prevent="selectFromTimetableTo({id:place.id, name: place.station})">
                                    <span class="icon">
                                       <i v-if="place.type === 1" class="ab-icon-home"></i>
                                      <i v-if="place.type === 2" class="ab-icon-job"></i>
                                      <i v-if="place.type === 3" class="ab-icon-train"></i>
                                      <i v-if="place.type === 4" class="ab-icon-events"></i>
                                    </span>
                                       {{ place.name }}
                                    </a>

                                    <a href="#" class="timetable_input_autocomplete_item"
                                       v-for="(place, index) in selectedPlaces"
                                       :key="'tosp-' + index"
                                       @click.prevent="selectFromTimetableTo(place)">
                                       <span class="icon"><i class="ab-icon-headquarters"></i></span>
                                       {{ place.name }}
                                    </a>
                                 </template>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="timetable_controls_wrapper pt0 time">
                  <label for="timetable_time">Datum / Uhrzeit</label>
                  <div class="timetable_datetime_wrapper">
                     <!-- <span class="input-txt">
                        <VueCtkDateTimePicker
                           v-model="date"
                           :id="timetable_time"
                           :format="'DD.MM.YYYY'"
                           :formatted="'DD.MM.YYYY'"
                           :label="''"
                           :locale="'de'"
                           :only-date="false"
                           :min-date="$moment().format('YYYY-MM-DD')"
                           :noClearButton="true"
                           :color="'#000000'"
                           :buttonColor="'#CE132D'"
                        ><a class="date_trigger"></a
                        ></VueCtkDateTimePicker>
                        <span class="timetable_date"> -->
                           <!-- {{ date }} -->
                        <!-- </span>
                     </span> -->

                     <div class="time-wrapper">
                        <div class="timetable_date_wrapper">
                               <img src="../../assets/images/time.svg" height="30"/>
                           <VueCtkDateTimePicker
                              v-model="date"
                              :id="timetable_time"
                              :format="'DD.MM.YYYY'"
                              :formatted="'DD.MM.YYYY'"
                              :label="''"
                              :locale="'de'"
                              :only-date="true"
                              :min-date="$moment().format('YYYY-MM-DD')"
                              :noClearButton="true"
                              :color="'#000000'"
                              :buttonColor="'#CE132D'"
                           ><a class="date_trigger"></a
                           ></VueCtkDateTimePicker>
                           <div class="timetable_date">
                              {{ date }}
                           </div>
                        </div>
                        <div class="timetable_time_wrapper">
                           <VueCtkDateTimePicker
                              v-model="time"
                              :format="'HH:mm'"
                              :formatted="'HH:mm'"
                              :label="''"
                              :locale="'de'"
                              :only-time="true"
                              :min-date="$moment().format('YYYY-DD-MM HH:mm a')"
                              :noClearButton="true"
                              :color="'#000000'"
                              :buttonColor="'#CE132D'"
                           ><a class="date_trigger"></a
                           ></VueCtkDateTimePicker>
                           <div class="timetable_time">
                              {{ timeComputed }}
                           </div>
                        </div>
                     </div>
                     <button class="plain" @click="resetDateAndTime">Jetzt</button>
                  </div>
               </div>
            </div>
            <div class="timetable_submit_wrapper d-flex flex-column"
                 v-if="timetableFromObject != null && timetableToObject != null && step == 1">
                 <div class="timetable_submit_wrapper_in">
                     <div class="timetable_inner_inner">
                        <button class="btn btn-red btn-suche animate__fadeInUp" @click="goToSecondStep()">
                           {{ __t('search') }}
                        </button>
                     </div>
                 </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import $ from "jquery";
import {mapState} from "vuex";
import {debounce} from "debounce";
import {config} from "../../config";

export default {
   name: "TimetableContentElement",
   components: {},
   props: {
      data: Object,
   },
   data() {
      return {
         step: 1,
         selectedOption: null,
         date: this.$moment().format("DD.MM.YYYY"),
         time: this.$moment().format("HH:mm"),
         departingTrains: "",

         timetableFrom: "",
         timetableTo: "",
         timetableFromObject: null,
         timetableToObject: null,
         timetable_time: "",

         timetableToLoading: false,
         timetableFromLoading: false,

         timetableFromActive: false,
         timetableToActive: false,
         timetableFromSelected: false,
         minCharsSearchStart: 0,

         editPlace: null,
         editPlacePopupVisible: false,
         newPlacePopupVisible: false,
         savedPlaces: [],
         timetableInformationPath: 'fahrplanauskunft'

      };
   },
   computed: {
      ...mapState([
         "authentication",
         "locales",
         "region",
         "settings",
         "timetable",
         "globalPopup",
         "autoSuggestPlaces",
         "timetableInformation",
      ]),
      timeComputed() {
         // if (this.time == this.$moment().format("hh:mm")) {
         //    return "Jetzt";
         // }
         return this.time;
      },
      selectedPlaces() {
         let selectedPlaces = JSON.parse(localStorage.getItem('selectedPlaces'));
         if (selectedPlaces === null) {
            return [];
         }

         return selectedPlaces;
      },
      departureMonitorMdUrl() {
         return this.settings?.all?.urls?.departure_monitor_md_url[this.locales.currentLocale];
      }
   },
   methods: {
      switchRoutes(){
         [this.timetableFrom, this.timetableTo] = [this.timetableTo, this.timetableFrom];
         [this.timetableFromObject, this.timetableToObject] = [this.timetableToObject, this.timetableFromObject];
      },
      navigate(url) {
         window.location.href = url;
         this.closePopup();
      },
      selectFromTimetableFrom(val) {
         this.timetableFrom = val.name;
         this.timetableFromActive = false;
         this.timetableFromSelected = true;
         this.timetableFromObject = val;

         this.addPlaceOnSelectedPlaces(val);
      },
      selectFromTimetableTo(val) {
         this.timetableTo = val.name;
         this.timetableToActive = false;
         this.timetableToObject = val;

         this.addPlaceOnSelectedPlaces(val);
      },
      goToSecondStep() {
         this.$store.commit("globalPopup/setTimetableOpen", false);
         this.$router.push({
            path: '/' + this.timetableInformationPath,
            query: {
               from: this.timetableFromObject.id,
               fromName: this.timetableFromObject.name,
               to: this.timetableToObject.id,
               toName: this.timetableToObject.name,
               date: this.date,
               time: this.time,
            },
         });
      },
      resetDateAndTime(){
         this.date = this.$moment().format('DD.MM.YYYY');
         this.time = this.$moment().format('HH:mm')
      },
      closePopup() {
         this.$store.commit('globalPopup/setTimetableOpen', !this.globalPopup.isTimetableOpen);
         this.$store.commit("autoSuggestPlaces/setPlaces", []);
         this.timetableFrom = "";
         this.timetableTo = "";
         this.timetableFromObject = null;
         this.timetableToObject = null;
         this.timetableFromSelected = false;
         this.$store.commit('timetable/clearData');
      },
      addPlaceOnSelectedPlaces(place) {
         let selectedPlaces = JSON.parse(localStorage.getItem('selectedPlaces'));
         if (selectedPlaces === null) {
            localStorage.setItem('selectedPlaces', JSON.stringify([place]));
         } else {
            let toBeAdded = true;
            selectedPlaces.forEach(function (val) {
               if (val.id === place.id) {
                  toBeAdded = false;
               }
            });

            if (toBeAdded) {
               selectedPlaces.push(place);
               localStorage.setItem('selectedPlaces', JSON.stringify(selectedPlaces));
            }
         }
      },
      updateSavedPlaces() {
         let myPlaces = JSON.parse(localStorage.getItem("myPlaces"));
         if (myPlaces !== null) {
            this.savedPlaces = myPlaces;
         }
      }
   },
   mounted() {
      const _self = this;
      $(document).mouseup(function (e) {
         const containerFrom = $(".timetable_from_wrapper");
         const containerTo = $(".timetable_to_wrapper");
         if (
            !containerFrom.is(e.target) &&
            containerFrom.has(e.target).length === 0
         ) {
            _self.timetableFromActive = false;
         }
         if (!containerTo.is(e.target) && containerTo.has(e.target).length === 0) {
            _self.timetableToActive = false;
         }
      });

      this.updateSavedPlaces()


   },
   created() {
      let self = this

      this.$store.dispatch("timetableInformation/getTimetableExtraInfo");
      this.$store.dispatch('page/getByID', config.timetablePageId).then((data) => {
         self.timetableInformationPath = data.slug
      })
   },
   watch: {
      'region.currentRegion': function () {
         this.$store.dispatch("timetableInformation/getTimetableExtraInfo");
      },
      'globalPopup.isTimetableOpen': function (val) {
         if (val && this.timetable.hasData) {
            this.date = this.timetable.date;
            this.time = this.timetable.time;

            this.timetableFrom = this.timetable.timetableFrom;
            this.timetableTo = this.timetable.timetableTo;
            this.timetableFromObject = this.timetable.timetableFromObject;
            this.timetableToObject = this.timetable.timetableToObject;
            this.timetableFromActive = false;
            this.timetableFromSelected = true;
         }
      },
      timetableFrom: debounce(function (val) {
         if (this.step === 1 && val && val.length > this.minCharsSearchStart) {
            this.timetableFromLoading = true;
            let self = this;
            this.$store.dispatch("autoSuggestPlaces/getAutoSuggestion", val).then(function () {
               self.timetableFromLoading = false;
            });

         }
      }, 800),
      timetableTo: debounce(function (val) {
         if (this.step === 1 && val && val.length > this.minCharsSearchStart) {
            this.timetableToLoading = true;
            let self = this;
            this.$store.dispatch("autoSuggestPlaces/getAutoSuggestion", val).then(function () {
               self.timetableToLoading = false;
            });
         }
      }, 800),
      newPlacePopupVisible: function () {
         let myPlaces = JSON.parse(localStorage.getItem("myPlaces"));
         if (myPlaces !== null) {
            this.savedPlaces = myPlaces;
         }
      },
   },
};
</script>
