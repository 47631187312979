<template>
    <div class="content_element timeline_element">
        <div class="container">
            <div class="timeline_wrapper d-flex flex-column flex-wrap">
                <div class="timeline_item d-flex flex-wrap"
                     v-for="(item, index) in content.years"
                     :key="index+'timeline'"
                     :class="{ 'align-self-end': index%2 == 0 }"
                >
                    <h6 class="timeline_year" v-html="yearPrint(item.year)"
                        :data-aos="[index%2 === 0 ? 'fade-left' : 'fade-right']"
                        :data-aos-delay="[10 * index]"></h6>
                    <!-- <div class="timeline_contents"
                         v-if="item.contents && item.contents.length"
                         :style="{ backgroundImage: 'url('+ mediaPath+encodeURI(item.image) +')' }"
                    > -->
                    <div class="timeline_contents"
                         :data-aos="[index%2 === 0 ? 'fade-left' : 'fade-right']"
                         :data-aos-delay="[50 * index]"
                         v-if="item.contents && item.contents.length"
                    >
                        <div class="timeline_content"
                             v-for="(content, index2) in item.contents"
                             :key="index+index2+'timeline_content'"
                        >
                            <nl2br tag="p" :text="content.content"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {config} from '../../config';

    export default {
        name: "TimeLine",
        props: {
            content: {
                type: Object,
                required: true
            }
        },
       computed: {
            mediaPath() {
                return config.mediaPath;
            }
        },
        methods: {
            yearPrint: function (year) {
                let text = '' +year;
                text = text.split('');
                return text[0]+text[1] +'<br/>'+ text[2]+text[3];
            }
        }
    }
</script>

<style scoped>

</style>
