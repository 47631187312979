<template>
   <div class="gallery-wrapper">
      <div class="content_element">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 ">
                  <div class="filter_list">
                     <p class="h5 text-center mb30">{{ content.title }}</p>

                     <ul>
                        <li v-for="(category, index) in gallery.all.categories" :key="index"
                            class="cursor-pointer"
                            :class="{ 'active' : activeCategories.includes(category.id) }">
                           <a @click.prevent="toggleCategory(category.id)">{{ category.title }}</a>
                        </li>
                     </ul>
                  </div>
               </div>

               <div class="gallery-holder">
                  <div class="col-sm-12"></div>
                  <template v-for="(image, index) in listOfImages">
                     <a href="#" @click.prevent="activatePopup(image)" class="gallery-item-box"
                        :key="index">
                        <div class="gallery-box-image"
                             :style="{backgroundImage:  'url(' + cmsAsset(image.image_thumb) + ')'}"></div>
                        <div class="gallery-box-name">
                           <h5 class="mb0">{{ image.title }}</h5>
                           <p>{{ image.copyright }}</p>
                        </div>
                     </a>
                  </template>
                  <div class="col-sm-12 text-center my-2" v-if="hasMore">
                     <button class="red-button" id="loadMore" @click.stop="loadGallery()" v-if="!loading">{{
                           __t('gallery.load_more')
                        }}
                     </button>
                     <button class="red-button" v-if="loading">{{ __t('gallery.loading') }}</button>
                  </div>
               </div>

               <div class="popup-gallery-box" v-if="popupOpen">
                  <div class="popup-holder">
                     <div class="inner-popup">
                        <a href="#" class="close_button" @click.prevent="popupOpen = false"></a>
                        <div class="popup-image"
                             :style="{backgroundImage:  'url(' + cmsAsset(image.image_thumb) + ')'}">
                        </div>
                        <div class="popup-content">
                           <h2>{{ image.title }}</h2>
                           <nl2br tag="p" :text="image.description"/>
                           <br/>
                           <p>{{ image.coopyright }}</p>
                        </div>
                     </div>
                     <a href="#" class="red-button"
                        @click.prevent="downloadFile(cmsAsset(image.image), image.image)">Download</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import axios from 'axios'
import {mapState} from "vuex";

export default {
   name: "GalleryList",
   components: {},
   props: {
      content: {
         type: Object,
         required: true
      }
   },
   data() {
      return {
         popupOpen: false,
         image: {
            image: '',
            title: '',
            description: '',
            copyright: '',
         },
         activeCategories: [],
         categoriesCount: 0,
         pageNr: 0,
         loading: true,
         hasMore: true
      }
   },
   computed: {
      ...mapState(['gallery']),
      listOfImages: function () {
         let articles = [];
         this.gallery.all.images.forEach((image) => {
            articles.push(image);
         });

         return articles;
      }
   },
   methods: {
      activatePopup(image) {
         this.image = image;
         this.popupOpen = true;
      },
      downloadFile(url, title) {
         axios({
            method: 'get',
            url,
            responseType: 'arraybuffer',
         }).then((response) => {
            this.forceFileDownload(response, title)
         })
      },
      forceFileDownload(response, title) {
         const url = window.URL.createObjectURL(new Blob([response.data]))
         const link = document.createElement('a')
         link.href = url
         link.setAttribute('download', title)
         document.body.appendChild(link)
         link.click()
      },
      getCategories(article) {
         let categories = [];

         article.categories.forEach(function (category) {
            categories.push(category.title);
         })

         return categories;
      },
      toggleCategory(id) {
         if (this.activeCategories.length === 1 && this.activeCategories[0] === id) {
            return;
         }

         if (this.activeCategories.length === this.categoriesCount) {
            this.activeCategories = this.activeCategories.filter((i) => i === id);
         } else if (this.activeCategories.includes(id)) {
            this.activeCategories = this.activeCategories.filter(i => i !== id);
         } else {
            this.activeCategories.push(id);
         }

         this.pageNr = 0
         this.loadGallery(true)
      },
      loadGallery(clear = false) {
         let self = this
         this.loading = true
         this.pageNr += 1
         this.$store.dispatch('gallery/loadAll', {
            page: this.pageNr,
            categories: this.activeCategories.join(','),
            clear: clear
         }).then(function (data) {
            if (self.categoriesCount === 0) {
               self.categoriesCount = data.categories.length;
               data.categories.forEach(function (category) {
                  self.activeCategories.push(category.id);
               })
            }
            self.loading = false;
            self.hasMore = data.has_more;
         });
      },
      isInViewPort(element) {
         if (this.loading) {
            return false;
         }

         if (element == null) {
            return false;
         }

         let distance = element.getBoundingClientRect()

         return (
            distance.top >= 0 &&
            distance.left >= 0 &&
            distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            distance.right <= (window.innerWidth || document.documentElement.clientWidth)
         )
      }
   },
   created() {
      this.loadGallery(true)
   },
   mounted() {
      window.addEventListener('scroll', () => {
         if (this.isInViewPort(document.querySelector('#loadMore'))) {
            this.loadGallery();
         }
      }, false);
   }
}
</script>
