<template>
    <div class="content_element stats_element">
        <div class="container">
            <div class="stats_scrollbar">
                <div class="stats_wrapper">
                    <div class="stats_container d-flex flex-wrap justify-content-center">
                        <div class="stats_item" v-for="(item, index) in content.items" :key="index+'stats_item'">
                            <h3 class="stats_title">{{item.title}}</h3>
                            <p>{{item.subtitle}}</p>
                        </div>
                    </div>
                    <div class="stats_content html-content" v-html="content.content"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Stats",
        props: {
            content: {
                type: Object,
                required: true
            }
        },
    }
</script>

<style scoped>

</style>
