<template>
    <div class="content_element job_offers_map_element">
        <GmapMap
                ref="mapRef"
                :center="{lat: 0, lng: 0}"
                :options="mapOptions"
                class="job_offers_map_wrapper"
        >
            <GmapMarker
                    v-for="(item, index) in job.all"
                    :key="index"
                    :position="{lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)}"
                    :clickable="true"
                    :icon="{
                    url: cmsAsset(settings.all.jobs_marker),
                }"
                    @click="infoWinOpen = item.id"
            />

            <JobInfoWindow v-for="(item, index) in job.all"
                           :key="index+'info-window'"
                           :job="item"
                           :opened="infoWinOpen == item.id"
            />


        </GmapMap>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {gmapApi} from 'vue2-google-maps';
    import JobInfoWindow from "../_common/_mapInfoWindows/JobInfoWindow";

    export default {
        name: "JobOffersMap",
        components: {JobInfoWindow},
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                mapOptions: {
                    zoomControl: true,
                    zoomControlOptions: {
                        position: 3
                    },
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: true,
                    fullscreenControl: false,
                    disableDefaultUi: false,
                    gestureHandling: 'greedy',
                },
                infoWinOpen: 0,
            }
        },
        computed: {
            ...mapState([
                'job',
                'settings'
            ]),
            google: gmapApi
        },
        methods: {
            mapBound() {
                if (this.job.all && this.job.all.length && this.$refs.mapRef) {
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        const bounds = new this.google.maps.LatLngBounds();
                        this.job.all.forEach((item) => {
                            bounds.extend({
                                lat: parseFloat(item.latitude),
                                lng: parseFloat(item.longitude)
                            });
                        });
                        map.fitBounds(bounds);
                    });
                }
            }
        },
        created() {
            this.$store.dispatch('job/getAll');
        },
        beforeMount() {
            this.mapOptions.styles = this.settings.all.map_style;
        },
        mounted() {
            this.$gmapApiPromiseLazy().then(() => {
                this.mapBound();
            });
        },
        watch: {
            'job.all': function () {
                this.mapBound();
            }
        }
    }
</script>
