<template>
   <div>
      <GmapMarker
         v-for="(item, index) in station.all"
         :key="index+'station_marker'"
         :position="{lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)}"
         :clickable="true"
         :options="{
            type: 1
         }"
         @click="clicked(item)"
         :icon="{url: cmsAsset(settings.all.stations_marker)}"/>

      <GmapInfoWindow
         v-if="currentInfoWindow"
         :options="{
                maxWidth: 200,
                pixelOffset: {
                    width: 0,
                    height: -48
                }
            }"
         :position="{lat: parseFloat(currentInfoWindow.latitude), lng: parseFloat(currentInfoWindow.longitude)}"
         :opened.sync="infoWindowOpen"
         @closeclick="infoWindowOpen = false; currentInfoWindow = null;">
         <div class="info-window">
            <div class="abellio_info_box">
               <div class="abellio_info_box_content">
                  <h6 class="abellio_info_box_title" v-if="currentInfoWindow.title">
                     {{ currentInfoWindow.title }}
                  </h6>
                  <p class="abellio_info_box_address">
                     <i class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14.385"
                             viewBox="0 0 10 14.385">
                           <path id="Shape"
                                 d="M5,14.385H5C4.954,14.324,0,8.406,0,5A5,5,0,1,1,10,5c0,3.237-4.944,9.322-4.994,9.383ZM5,2.822a2.133,2.133,0,1,0,1.508.625A2.121,2.121,0,0,0,5,2.822Z"
                                 transform="translate(0)" fill="#ffffff"></path>
                        </svg>
                     </i>
                     {{ currentInfoWindow.street }}<br/>
                     {{ currentInfoWindow.zip }} {{ currentInfoWindow.city.title }}
                  </p>

                  <p v-for="(line, index) in currentInfoWindow.lines"
                     :key="index" class="mb0">
                     <router-link :to="{ name: 'line', params: { slug: line.slug } }"
                                  class="h3 abellio_info_box_title line-slider-title pr10 text-decoration-none"
                                  :style="{ color: line.color }">
                        {{ line.title }}
                     </router-link>
                  </p>
                  <p>&nbsp;</p>

                  <router-link targ :to="{ name: 'station', params: {slug: currentInfoWindow.slug}}"
                               class="abellio_info_box_more">
                     {{ __t('learn_more') }}
                  </router-link>
               </div>
            </div>
         </div>
      </GmapInfoWindow>
   </div>
</template>

<script>
   import {mapState} from 'vuex';

   export default {
      name: "AbellioWorldStations",
      components: {},
      props: {
         settings: {
            type: Object,
            required: true
         }
      },
      data() {
         return {
            infoWindowOpen: false,
            currentInfoWindow: null
         }
      },
      created() {
         this.$store.dispatch('station/getAll');
      },
      computed: {
         ...mapState([
            'station'
         ])
      },
      methods: {
         clicked(station) {
            this.infoWindowOpen = true;
            this.currentInfoWindow = station;
            this.$emit('markerClicked', {
               lat: parseFloat(station.latitude),
               lng: parseFloat(station.longitude),
            }, this);
         },
         showContent(item) {
            this.infoWindowOpen = false;
            this.currentInfoWindow = null;
            this.$emit('showContent', item, 'station');
         }
      },
   }
</script>
