<template>
    <FormBuilder :id="content.id" :content="{title: content.title, form_elements: content.questions, subtitle: content.subtitle,  success_message: content.success_message[0],template: content._template}"/>
</template>

<script>
    import FormBuilder from "./FormBuilder";

    export default {
        name: "FormSelector",
        components: {FormBuilder},
        props: {
            content: {
                type: Object,
                required: true
            }
        }
    }
</script>
