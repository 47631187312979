<template>
    <div>
        <div class="info-window">
            <div class="abellio_info_box job_info_box">
                <div class="abellio_info_box_image">
                    <img v-if="'sdsddssd'" :src="'sdsddsdds'" :alt="'sdsddsd'">
                </div>
                <div class="abellio_info_box_content">
                    <p class="abellio_info_box_sub_title" v-if="'sdsdsdsdsd'">sdsddsdsdss</p>
                    <h6 class="abellio_info_box_title">sdsdsdsdsdsdsd</h6>
                    <p class="abellio_info_box_address" v-if="'sdsdsddsddds'">
                        <i class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14.385"
                                 viewBox="0 0 10 14.385">
                                <path id="Shape"
                                      d="M5,14.385H5C4.954,14.324,0,8.406,0,5A5,5,0,1,1,10,5c0,3.237-4.944,9.322-4.994,9.383ZM5,2.822a2.133,2.133,0,1,0,1.508.625A2.121,2.121,0,0,0,5,2.822Z"
                                      transform="translate(0)" fill="#ffffff"/>
                            </svg>
                        </i>
                        <nl2br tag="span" :text="'sdsdsdssdsdsdds'"/>
                    </p>
                    <div class="d-flex justify-content-between align-items-start">
                        <a class="job_info_box_apply_button" href="#">
                            <i class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <path id="Combined_Shape" data-name="Combined Shape"
                                          d="M1.5,14A1.5,1.5,0,0,1,0,12.5V2.5A1.5,1.5,0,0,1,1.5,1h6a.5.5,0,1,1,0,1h-6a.5.5,0,0,0-.5.5v10a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5v-5a.5.5,0,0,1,1,0v5A1.5,1.5,0,0,1,10.5,14Zm1.842-3.026a.5.5,0,0,1-.317-.633l1-3a.516.516,0,0,1,.121-.2L10.733.561A1.914,1.914,0,1,1,13.44,3.268L6.854,9.853a.508.508,0,0,1-.2.122l-3,1a.5.5,0,0,1-.315,0Z"
                                          transform="translate(0 0)" fill="#ffffff"/>
                                </svg>
                            </i>
                            Jetzt Bewerben
                        </a>
                        <div class="job_info_box_actions">
                            <a href="#" class="job_info_box_action_button">
                                <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.201" height="12"
                                         viewBox="0 0 11.201 12">
                                        <g id="Group" transform="translate(-0.967)">
                                            <path id="Combined_Shape" data-name="Combined Shape"
                                                  d="M11.2,12H5.928V6.351H11.2V12h0Zm-2.984-.957h.7V10.3h-.7ZM6.81,9.527h3.508v-.7H6.81ZM8.216,8.055h.7V7.308h-.7ZM5.271,12H0V6.351H5.272V12h0ZM2.637,9.673l1.007,1.08.464-.5L3.1,9.175,4.109,8.095l-.464-.5L2.636,8.679,1.627,7.6l-.464.5L2.172,9.175,1.164,10.256l.464.5,1.008-1.08h0ZM11.2,5.648H5.928V0H11.2V5.648h0ZM6.81,3.176h3.508v-.7H6.81ZM5.271,5.648H0V0H5.272V5.648h0ZM.882,3.176H2.308V4.7h.656V3.176H4.39v-.7H2.964V.945H2.308V2.472H.882Z"
                                                  transform="translate(0.967)" fill="#ffffff"/>
                                        </g>
                                    </svg>
                                </i>
                            </a>
                            <a href="#" class="job_info_box_action_button">
                                <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.833" height="14"
                                         viewBox="0 0 12.833 14">
                                        <path id="Combined_Shape" data-name="Combined Shape"
                                              d="M8.167,11.667a2.331,2.331,0,0,1,.066-.554L3.956,8.675a2.333,2.333,0,1,1,0-3.35L8.233,2.887a2.339,2.339,0,1,1,.553,1.027L4.566,6.321a2.338,2.338,0,0,1,0,1.359l4.22,2.406a2.332,2.332,0,1,1-.62,1.582Z"
                                              fill="#ffffff"/>
                                    </svg>
                                </i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-for="(item, index) in line.all">
            <GmapMarker
                v-for="(polyLineItem, polyLineIndex) in item.map_line"
                :key="index + '_' + polyLineIndex + 'line_marker1'"
                :position="{lat: parseFloat(polyLineItem[0].latitude), lng: parseFloat(polyLineItem[0].longitude)}"
                :icon="{url: assetsPath + settings.all.lines_marker}"
                :clickable="true"
                @click="clicked(item, polyLineItem[0].latitude,polyLineItem[0].longitude)"/>
            <GmapMarker
                v-for="(polyLineItem, polyLineIndex) in item.map_line"
                :key="index + '_' + polyLineIndex + 'line_marker2'"
                :position="{lat: parseFloat(polyLineItem[polyLineItem.length -1].latitude), lng: parseFloat(polyLineItem[polyLineItem.length -1].longitude)}"
                :icon="{url: assetsPath + settings.all.lines_marker}"
                :clickable="true"
                @click="clicked(item, polyLineItem[polyLineItem.length -1].latitude,polyLineItem[polyLineItem.length -1].longitude)"/>
        </template>
        <GmapPolyline
            v-for="(polyLineItem, polyLineIndex) in polyLines"
            :key="polyLineIndex + 'lines_polyline'"
            :path="polyLineItem.polyLines"
            :options="{ strokeColor: polyLineItem.color}"/>

        <GmapInfoWindow
            v-if="currentInfoWindow"
            :options="{
                maxWidth: 200,
                pixelOffset: {
                    width: 0,
                    height: -48
                }
            }"
            :position="{lat: parseFloat(currentInfoWindow.latitude), lng: parseFloat(currentInfoWindow.longitude)}"
            :opened="infoWindowOpen"
            @closeclick="infoWindowOpen = false; currentInfoWindow = null;">
            <div class="info-window">
                <div class="abellio_info_box">
                    <div class="abellio_info_box_content">
                        <h6 class="abellio_info_box_title"
                            :style="'color:' + currentInfoWindow.color"
                            v-if="currentInfoWindow.title">
                            {{ currentInfoWindow.title }}
                        </h6>
                        <p v-if="currentInfoWindow.train">
                            Train: {{ currentInfoWindow.train.title}}<br/>
                            Distance: {{ currentInfoWindow.distance}}km
                        </p>
                        <a href="#" @click.prevent="showContent(currentInfoWindow)" class="abellio_info_box_more"> mehr erfahren </a>
                    </div>
                </div>
            </div>
        </GmapInfoWindow>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {config} from "../../../config";

    export default {
        name: "AbellioWorldLines",
        components: {},
        props: {
            settings: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                infoWindowOpen: false,
                currentInfoWindow: null
            }
        },
        created() {
            this.$store.dispatch('line/getAll');
        },
        computed: {
            ...mapState([
                'line'
            ]),
            polyLines() {
                let polylines = [];
                this.line.all.forEach((item) => {
                      item.map_line.forEach((polyLine) => {
                         let newPolyLine = [];
                         polyLine.forEach((point) => {
                            newPolyLine.push({
                               lat: parseFloat(point.latitude),
                               lng: parseFloat(point.longitude)
                            });
                         });
                         polylines.push({
                            line: item.title,
                            color: item.color,
                            polyLines: newPolyLine
                         });
                      });

                });
                return polylines;
            },
            assetsPath() {
                return config.apiUrl + '/storage/app/media';
            },
        },
        methods: {
            clicked(line, latitude, longitude) {
                this.infoWindowOpen = true;
                this.currentInfoWindow = line;
                this.currentInfoWindow.latitude = latitude;
                this.currentInfoWindow.longitude = longitude;

                this.$emit('markerClicked', {
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude),
                }, this);
            },
            showContent(item) {
                this.infoWindowOpen = false;
                this.currentInfoWindow = null;
                this.$emit('showContent', item, 'line');
            }
        }
    }
</script>


<style lang="scss" scoped>
    .info-window {
        position: absolute;
        background: #FFF;
        width: 200px;
        height: 200px;
        z-index: 1000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
