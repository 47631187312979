<template>
   <div class="content_element carousel_content_element">
      <div class="container">
         <div class="row">
            <div class="col-12 col-sm-12 col-md-3 col-lg-2">
               <nl2br tag="h2" :text="content.title"/>
            </div>
            <div class="col-12 col-sm-12 col-md-9 col-lg-10">
               <div class="carousel_content_wrapper">
                  <swiper
                     class="swiper"
                     ref="swiperCarousel"
                     :options="swiperOption"
                     :auto-update="true"
                     :auto-destroy="true"
                     v-if="content.items && content.items.length">
                     <swiper-slide v-for="(item, index) in content.items" :key="index + 'AWcarouselContent'">
                        <div
                           class="abellio_info_box"
                           :class="{ is_active: activeContentID == item.id }"
                        >
                           <div class="abellio_info_box_image" v-if="item.image">
                              <img class="mw-100 mh-100 vw-100 vh-100 object-fit-cover" :src="cmsAsset(item.image)"
                                   :alt="item.title"/>
                           </div>
                           <div class="abellio_info_box_content">
                              <h6
                                 class="abellio_info_box_title"
                                 :style="'color:' + item.color"
                              >
                                 {{ item.title }}
                              </h6>
                              <p v-html="item.short_content"></p>
                           </div>

                           <router-link :to="url(item)" class="abellio_info_box_more">
                              Mehr erfahren
                           </router-link>
                        </div>
                     </swiper-slide>
                     <div class="swiper-pagination" slot="pagination"></div>
                  </swiper>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import $ from "jquery";
   import {mapState} from "vuex";
   import {Swiper, SwiperSlide} from "vue-awesome-swiper";

   export default {
      name: "AbellioWorldCarouselContent",
      components: {
         Swiper,
         SwiperSlide,
      },
      props: {
         content: {
            type: Object,
            required: true,
         },
         id: {
            type: Number,
            required: true,
         },
      },
      data() {
         return {
            swiperOption: {
               slidesPerView: 1.5,
               spaceBetween: 16,
               draggable: true,
               breakpoints: {
                  // when window width is >= 320px
                  375: {
                     slidesPerView: 1,
                     spaceBetween: 16,
                  },
                  414: {
                     slidesPerView: 1,
                     spaceBetween: 16,
                  },
                  576: {
                     slidesPerView: 2.2,
                     spaceBetween: 16,
                  },
                  768: {
                     slidesPerView: 2.5,
                     spaceBetween: 16,
                  },
                  992: {
                     slidesPerView: 3.5,
                     spaceBetween: 16,
                  },
                  1280: {
                     slidesPerView: 3.5,
                     spaceBetween: 16,
                  },
               },
               pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
               },
            },
            activeContentID: null,
         };
      },
      computed: {
         ...mapState(["locales"]),
         selectedContent: function () {
            return this.content.items.find((item) => {
               return item.id == this.activeContentID;
            });
         }
      },
      methods: {
         url(event) {
            return {
               name: 'events',
               params: {
                  slug: event.slug,
                  locale: this.locales.currentLocale != this.locales.defaultLocale ? this.locales.currentLocale : null,
               },
            }
         },
         carouselWrapperWidth: function () {
            let element = $(this.$el).find(".carousel_content_wrapper");
            let offsetLeft = element.offset().left;
            element.outerWidth($(this.$el).outerWidth() - offsetLeft);

            this.$nextTick(() => {
               if (this.$refs.swiperCarousel) {
                  this.$refs.swiperCarousel.$swiper.update();
               }
            });
         },
         openContent(id) {
            this.activeContentID = id;
            this.toSlide(id);
         },
         toSlide(id) {
            let index = 0;
            for (let i = 0; i < this.content.items.length; i++) {
               if (this.content.items[i].id == id) {
                  index = i;
                  break;
               }
            }
            this.$refs.swiperCarousel.$swiper.slideTo(index, 0);
         },
      },
      mounted() {
         this.carouselWrapperWidth();
         window.addEventListener("resize", this.carouselWrapperWidth);

         this.activeContentID = this.id;
         this.toSlide(this.id);
      },
      /*watch: {
                'activeContentID': function (val) {


                }
            }*/
   };
</script>

<style scoped>
   .swiper-slide {
      height: auto;
      padding: 5px 0 15px 0;
   }
</style>
