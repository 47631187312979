<template>
   <div class="content_element full_image_element">
      <img class="big_image" :src="mediaPath+content.image" alt="">
      <div class="copyright_sign" v-if="content.copyright">
         <p>{{ content.copyright }}</p>
      </div>
   </div>
</template>

<script>
   import {config} from '../../config';

   export default {
      name: "FullImage",
      props: {
         content: {
            type: Object,
            required: true
         }
      },
      computed: {
         mediaPath() {
            return config.mediaPath;
         }
      }
   }
</script>

<style scoped>

</style>
