<script>
import Content from "./Content";
import JobsCarousel from './JobsCarousel';
import JobsSearch from './JobsSearch';
import ContentBlocks from './ContentBlocks';
import JobOffersMap from './JobOffersMap';
import Banner from "./Banner";
import AccordionForm from "./AccordionForm";
import TrafficInfoBlocks from "./TrafficInfoBlocks";
import SubNavigation from "./SubNavigation";
import FullImage from "./FullImage";
import CarouselContent from "./CarouselContent";
import TitleBigImage from "./TitleBigImage";
import ColumnsContents from "./ColumnsContents";
import PlaceInfo from "./PlaceInfo";
import Stats from "./Stats";
import TimeLine from "./TimeLine";
import FormBuilder from "./FormBuilder";
import Accordion from "./Accordion";
import AccordionWithTabs from "./AccordionWithTabs";
import EmployeesCarousel from "./EmployeesCarousel";
import StationsList from "./StationsList";
import AbellioWorld from "./AbellioWorld";
import Timetable from "./Timetable";
import MainForm from "./MainForm";
import TrafficInformation from "./TrafficInformation";
import TrafficInformationMessage from "./TrafficInformationMessage";
import BlogView from "./BlogView";
import WarningMessage from "./WarningMessage";
import FaqList from "./FaqList";
import GalleryList from "./GalleryList";
import JobsList from "./JobsList";
import FormSelector from "./FormSelector";
import NewsList from "./NewsList";
import BreadcrumbContentElement from "./BreadcrumbContentElement";
import TimetableContentElement from "./TimetableContentElement";
import ScrollTo from "./ScrollTo";
import AlertMessage from "./AlertMessage";
import Button from "./Button";
import ImagesRow from "./ImagesRow";
import TextBlock from "./TextBlock";
import ImageAndContent from "./ImageAndContent";

export default {
  functional: true,
  name: "ContentElements",
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  render(createElement, context) {
    let render = [];
    switch (context.props.content._group) {
      case 'banner':
        render.push(createElement(Banner, {props: context.props}));
        break;
      case 'breadcrumb':
        render.push(createElement(BreadcrumbContentElement, {props: context.props}));
        break;
      case 'content':
        render.push(createElement(Content, {props: context.props}));
        break;
      case 'traffic_info_blocks':
        render.push(createElement(TrafficInfoBlocks, {props: context.props}));
        break;
      case 'sub_navigation':
        render.push(createElement(SubNavigation, {props: context.props}));
        break;
      case 'full_image':
        render.push(createElement(FullImage, {props: context.props}));
        break;
      case 'carousel_content':
        render.push(createElement(CarouselContent, {props: context.props}));
        break;
      case 'title_big_image':
        render.push(createElement(TitleBigImage, {props: context.props}));
        break;
      case 'columns_contents':
        render.push(createElement(ColumnsContents, {props: context.props}));
        break;
      case 'place_info':
        render.push(createElement(PlaceInfo, {props: context.props}));
        break;
      case 'stats':
        render.push(createElement(Stats, {props: context.props}));
        break;
      case 'timeline':
        render.push(createElement(TimeLine, {props: context.props}));
        break;
      case 'accordion':
        render.push(createElement(Accordion, {props: context.props}));
        break;
      case 'accordion_with_tabs':
        render.push(createElement(AccordionWithTabs, {props: context.props}));
        break;
      case 'form_builder':
        render.push(createElement(FormBuilder, {props: context.props}));
        break;
      case 'main_form':
        render.push(createElement(MainForm, {props: context.props}));
        break;
      case 'jobs_carousel':
        render.push(createElement(JobsCarousel, {props: context.props}));
        break;
      case 'jobs_search':
        render.push(createElement(JobsSearch, {props: context.props}));
        break;
      case 'content_blocks':
        render.push(createElement(ContentBlocks, {props: context.props}));
        break;
      case 'employees_carousel':
        render.push(createElement(EmployeesCarousel, {props: context.props}));
        break;
      case 'job_offers_map':
        render.push(createElement(JobOffersMap, {props: context.props}));
        break;
      case 'stations_list':
        render.push(createElement(StationsList, {props: context.props}));
        break;
      case 'abellio_world':
        render.push(createElement(AbellioWorld, {props: context.props}));
        break;
      case 'timetable':
        render.push(createElement(Timetable, {props: context.props}));
        break;
      case 'traffic_information':
        render.push(createElement(TrafficInformation, {props: context.props}));
        break;
      case 'traffic_information_message':
        render.push(createElement(TrafficInformationMessage, {props: context.props}));
        break;
      case 'blog_view':
        render.push(createElement(BlogView, {props: context.props}));
        break;
      case 'warning_message':
        render.push(createElement(WarningMessage, {props: context.props}));
        break;
      case 'faq_list':
        render.push(createElement(FaqList, {props: context.props}));
        break;
      case 'gallery_list':
        render.push(createElement(GalleryList, {props: context.props}));
        break;
      case 'jobs_list':
        render.push(createElement(JobsList, {props: context.props}));
        break;
      case 'news_list':
        render.push(createElement(NewsList, {props: context.props}));
        break;
      case 'form_selector':
        render.push(createElement(FormSelector, {props: context.props}));
        break;
      case 'accordion_form':
        render.push(createElement(AccordionForm, {props: context.props}));
        break;
      case 'scroll_to':
        render.push(createElement(ScrollTo, {props: context.props}));
        break;
      case 'button':
        render.push(createElement(Button, {props: context.props}));
        break;
      case 'full_timetable':
        render.push(createElement(TimetableContentElement, {props: context.props}));
        break;
      case 'alert_message':
        render.push(createElement(AlertMessage, {props: context.props}));
        break;
      case 'images_row':
        render.push(createElement(ImagesRow, {props: context.props}));
        break;
      case 'text_block':
        render.push(createElement(TextBlock, {props: context.props}));
        break;
      case 'image_and_content':
        render.push(createElement(ImageAndContent, {props: context.props}));
        break;
    }
    return render;
  }
}
</script>
