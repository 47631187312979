<template>
   <transition name="fade" v-if="visible">
      <div class="alert-warning error-message">
         <div class="container">
            <div class="error-message-left">
               <i class="icon ab-icon-warning"></i>
            </div>
            <div class="error-message-right">
               <h4 class="mb5"><i class="icon ab-icon-warning"></i> {{ content.title }}</h4>
               <p class="content mb0" v-html="content.content"></p>
            </div>
            <div class="close-alert-message cursor-pointer" @click="visible = !visible">
               <span></span>
            </div>
         </div>
      </div>
   </transition>
</template>

<script>
export default {
   name: "AlertMessage",
   props: {
      content: {
         type: Object,
         required: true
      }
   },
   data() {
      return {
         visible: true
      }
   }
}
</script>
