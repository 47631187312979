<template>
   <div v-if="content.buttonFile">
      <div class="content_element content_blocks_element">
         <div class="container">
            <div class="button-wrapper d-flex align-items-center justify-content-center">
               <button class="$abellio-gray" @click="showMapModal = true">{{ content.buttonText }}</button>
            </div>
         </div>
      </div>

      <Modal
         :additionalClass="'map-modal'"
         @modalClose="showMapModal = false"
         :visible="showMapModal === true"
      >
         <template slot="modalContent">
            <h3>{{content.modalTitle }}</h3>
            <img :src="cmsAsset(content.buttonFile)" alt="Map example" />
<!--            <div class="col-12 col-md-6 mb30" v-if="item.image">-->
<!--               <div class="content_block_image" :style="{ backgroundImage:  'url('+ cmsAsset(item.image) +')'}">-->
<!--                  <img :src="cmsAsset(item.image)" alt="">-->
<!--                  <p v-if="item.image_caption || true" class="content_block_image_caption">{{item.image_caption}}</p>-->
<!--               </div>-->
<!--            </div>-->
         </template>
      </Modal>
   </div>
</template>

<script>
   import Modal from "@/components/_common/Modal";

   export default {
      name: "Button",
      components: {
         Modal
      },
      props: {
         content: {
            type: Object,
            required: true
         }
      },
      data() {
         return {
            showMapModal: false
         }
      }
   }
</script>
