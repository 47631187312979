<template>
 <div class="content_element accordion_element aktionen-section">
  <div class="container">
   <div class="row">
    <div class="col-sm-12">
     <div class="filter_list">
      <ul>
       <li
        v-for="(category, index) in blog.all.categories"
        :key="index"
        class="cursor-pointer"
        :class="{ active: activeCategories.includes(category.id) }"
       >
        <a @click.prevent="toggleCategory(category.id)">{{ category.title }}</a>
       </li>
      </ul>
     </div>
    </div>
     <div class="col-12 blog-card-holder">
      <template v-for="(article, index) in listOfArticles">
      <transition appear name="slide-fade" :key="index">ß
        <div class="blog-card large" :key="index">
          <div class="inner">
          <a
            target="_blank"
            v-if="article.external_link && article.external_link !== ''"
            :href="article.external_link"
          >
              <div
              class="blog-card-image"
              v-if="article.image"
              :style="{ backgroundImage: 'url(' + cmsAsset(article.image) + ')' }"
              ></div>

              <div
              class="blog-card-image"
              v-else
              :style="{ backgroundColor: article.image_color }"
              ></div>

              <div class="blog-card-content">
                <div class="blog-card-content-bottom">
                  <span class="tab-item sm">{{ getCategories(article).join(", ") }}</span>
                  <h3 class="title">{{ article.title }}</h3>
                  <p>{{ article.teaser }}</p>
                </div>
              </div>
          </a>

          <router-link
            v-else
            :to="{ name: 'blogArticle', params: { slug: article.slug } }"
          >
            <div
            class="blog-card-image"
            v-if="article.image"
            :style="{ backgroundImage: 'url(' + cmsAsset(article.image) + ')' }"
            ></div>

            <div
            class="blog-card-image"
            v-else
            :style="{ backgroundColor: article.image_color }"
            ></div>

            <div class="blog-card-content">
              <div class="blog-card-content-bottom">
                <span class="tab-item sm">{{ getCategories(article).join(", ") }}</span>
                <h3 class="title">{{ article.title }}</h3>
                <p>{{ article.teaser }}</p>
              </div>
            </div>
          </router-link>
          </div>
        </div>
     </transition>
    </template>
      </div>
      <div class="content-back-btn-wrapper">
        <router-link :to="{path: backButtonPath}" v-if="showBackButton && backButtonPath" class="zuruck_button">
          {{ __t('back') }}
        </router-link>
        <a href="#" @click.prevent="$router.go(-1)" v-if="showBackButton && backButtonPath == null" class="zuruck_button">
          {{ __t('back') }}
        </a>
      </div>
   </div>
  </div>
 </div>
</template>

<script>
import { mapState } from "vuex";

export default {
 name: "BlogView",
 components: {},
 props: {
  content: {
   type: Object,
   required: true,
  },
  showBackButton: {
    type: Boolean,
    required: false,
    default: true
  },
  backButtonPath: {
    type: String,
    required: false,
    default: null
  }
 },
 data() {
  return {
   activeCategories: [],
  };
 },
 computed: {
  ...mapState(["blog"]),
  listOfArticles: function () {
   let articles = [];
   this.blog.all.articles.forEach((article) => {
    let match = false;
    article.categories.forEach((category) => {
     if (this.activeCategories.includes(category.id)) {
      match = true;
      return true;
     }
    });

    if (match) {
     articles.push(article);
    }
   });

   return articles;
  },
 },
 methods: {
  getCategories(article) {
   let categories = [];

   article.categories.forEach(function (category) {
    categories.push(category.title);
   });

   return categories;
  },
  toggleCategory(id) {
   if (this.activeCategories.includes(id)) {
    this.activeCategories = this.activeCategories.filter((i) => i !== id);
    return;
   }

   this.activeCategories.push(id);
  },
 },
 created() {
  let self = this;
  this.$store.dispatch("blog/loadAll").then(function (data) {
   data.categories.forEach(function (category) {
    self.activeCategories.push(category.id);
   });
  });
 },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
 transition: all 0.3s ease;
}

.slide-fade-leave-active {
 transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
   /* .slide-fade-leave-active below version 2.1.8 */ {
 transform: translateX(10px);
 opacity: 0;
}
</style>
