var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.office.all),function(item,index){return _c('GmapMarker',{key:index+'office_marker',attrs:{"position":{lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)},"clickable":true,"icon":{url: _vm.cmsAsset(_vm.settings.all.abellio_marker)}},on:{"click":function($event){return _vm.clicked(item)}}})}),(_vm.currentInfoWindow)?_c('GmapInfoWindow',{attrs:{"options":{
            maxWidth: 200,
            pixelOffset: {
                width: 0,
                height: -48
            }
        },"position":{lat: parseFloat(_vm.currentInfoWindow.latitude), lng: parseFloat(_vm.currentInfoWindow.longitude)},"opened":_vm.infoWindowOpen},on:{"update:opened":function($event){_vm.infoWindowOpen=$event},"closeclick":function($event){_vm.infoWindowOpen = false; _vm.currentInfoWindow = null;}}},[_c('div',{staticClass:"info-window"},[_c('div',{staticClass:"abellio_info_box"},[_c('div',{staticClass:"abellio_info_box_content"},[(_vm.currentInfoWindow.title)?_c('h6',{staticClass:"abellio_info_box_title"},[_vm._v(" "+_vm._s(_vm.currentInfoWindow.title)+" ")]):_vm._e(),_c('p',{staticClass:"abellio_info_box_address"},[_c('i',{staticClass:"icon"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10","height":"14.385","viewBox":"0 0 10 14.385"}},[_c('path',{attrs:{"id":"Shape","d":"M5,14.385H5C4.954,14.324,0,8.406,0,5A5,5,0,1,1,10,5c0,3.237-4.944,9.322-4.994,9.383ZM5,2.822a2.133,2.133,0,1,0,1.508.625A2.121,2.121,0,0,0,5,2.822Z","transform":"translate(0)","fill":"#ffffff"}})])]),_vm._v(" "+_vm._s(_vm.currentInfoWindow.street)+" "+_vm._s(_vm.currentInfoWindow.zip)+" "+_vm._s(_vm.currentInfoWindow.city.title)+" ")]),_c('a',{staticClass:"abellio_info_box_more",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showContent(_vm.currentInfoWindow)}}},[_vm._v(" mehr erfahren ")])])])])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }