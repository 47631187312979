<template>
   <div class="row">
      <div
         v-for="(item, index) in fields"
         :class="item.css_class"
         :key="'input_main_form_'+ index"
      >
         <validate v-if="item._group == 'text' && item.is_visible" :tabindex="index+1">
            <InputText
               :name="item.name"
               :label="item.label"
               :placeholder="item.placeholder"
               :inputId="'input_'+ content.id +'_'+ index"
               v-model="model[item.name]"
               :required="item.is_required == 1"
               :disabled="false"
            />
            <field-messages :name="item.name" class="vf-error_message">
               <template slot="required" slot-scope="state">
                  <span v-if="state.$dirty || state.$submitted">{{ errorMessage }}</span>
               </template>
            </field-messages>
         </validate>

         <validate v-else-if="item._group == 'textarea' && item.is_visible" :tabindex="index+1">
            <InputTextarea
               :name="item.name"
               :label="item.label"
               :placeholder="item.placeholder"
               :inputId="'input_'+ content.id +'_'+ index"
               v-model="model[item.name]"
               :required="item.is_required == 1"
               :disabled="false"
            />
            <field-messages :name="item.name" class="vf-error_message">
               <template slot="required" slot-scope="state">
                  <span v-if="state.$dirty || state.$submitted">{{ errorMessage }}</span>
               </template>
            </field-messages>
         </validate>

         <validate v-else-if="item._group == 'email' && item.is_visible" :tabindex="index+1">
            <InputText
               :name="item.name"
               :label="item.label"
               :placeholder="item.placeholder"
               :inputId="'input_'+ content.id +'_'+ index"
               v-model="model[item.name]"
               :required="item.is_required == 1"
               type="email"
               :disabled="false"
            />
            <field-messages :name="item.name" class="vf-error_message">
               <template slot="required" slot-scope="state">
                  <span v-if="state.$dirty || state.$submitted">{{ errorMessage }}</span>
               </template>
               <template slot="email" slot-scope="state">
                  <span v-if="state.$submitted && !state.$valid && state.$dirty">{{ __t('form.invalid_email') }}</span>
               </template>
            </field-messages>
         </validate>

         <validate v-else-if="item._group == 'dropdown' && item.is_visible" :tabindex="index+1">
            <InputSelect
               :name="item.name"
               :label="item.label"
               :placeholder="item.placeholder"
               :inputId="'input_'+ content.id +'_'+ index"
               v-model="model[item.name]"
               :options="item.options"
               :required="item.is_required == 1"
               @update="selectUpdated(item)"
               :disabled="false"
            />
            <field-messages :name="item.name" class="vf-error_message">
               <template slot="required" slot-scope="state">
                  <span v-if="state.$dirty || state.$submitted">{{ errorMessage }}</span>
               </template>
            </field-messages>
         </validate>

         <validate v-else-if="item._group == 'checkboxes' && item.is_visible" :tabindex="index+1">
            <InputCheckboxes
               :name="item.name"
               :label="item.label"
               :inputId="'input_'+ content.id +'_'+ index"
               v-model="model[item.name]"
               :options="item.options"
               :required="item.is_required == 1"
               :disabled="false"
            />
            <field-messages :name="item.name" class="vf-error_message">
               <template slot="required" slot-scope="state">
                  <span v-if="state.$dirty || state.$submitted">{{ errorMessage }}</span>
               </template>
            </field-messages>
         </validate>

         <validate v-else-if="item._group == 'radio_buttons' && item.is_visible" :tabindex="index+1">
            <InputRadioButtons
               :name="item.name"
               :label="item.label"
               :inputId="'input_'+ content.id +'_'+ index"
               v-model="model[item.name]"
               :options="item.options"
               :required="item.is_required == 1"
               :disabled="false"
            />
            <field-messages :name="item.name" class="vf-error_message">
               <template slot="required" slot-scope="state">
                  <span v-if="state.$dirty || state.$submitted">{{ errorMessage }}</span>
               </template>
            </field-messages>
         </validate>

         <validate v-else-if="item._group == 'datepicker' && item.is_visible" :tabindex="index+1">
            <InputDatePicker
               :name="item.name"
               :label="item.label"
               :inputId="'input_'+ content.id +'_'+ index"
               v-model="model[item.name]"
               :required="item.is_required == 1"></InputDatePicker>
            <field-messages :name="item.name" class="vf-error_message">
               <template slot="required" slot-scope="state">
                  <span v-if="state.$dirty || state.$submitted">{{ errorMessage }}</span>
               </template>
            </field-messages>
         </validate>

         <validate v-else-if="item._group == 'timepicker' && item.is_visible" :tabindex="index+1">
            <InputTimePicker
               :name="item.name"
               :label="item.label"
               :inputId="'input_'+ content.id +'_'+ index"
               v-model="model[item.name]"
               :required="item.is_required == 1"></InputTimePicker>
            <field-messages :name="item.name" class="vf-error_message">
               <template slot="required" slot-scope="state">
                  <span v-if="state.$dirty || state.$submitted">{{ errorMessage }}</span>
               </template>
            </field-messages>
         </validate>

         <validate v-else-if="item._group == 'description' && item.is_visible" :tabindex="index+1">
            <p class="mt-20 pl5 small">{{ item.label }}</p>
         </validate>
      </div>
   </div>
</template>

<script>
   import {
      InputText,
      InputSelect,
      InputTextarea,
      InputCheckboxes,
      InputRadioButtons,
      InputDatePicker,
      InputTimePicker
   } from '../../components/_form';

   export default {
      name: "FormRenderer",
      components: {
         InputTimePicker,
         InputDatePicker,
         InputText,
         InputSelect,
         InputTextarea,
         InputCheckboxes,
         InputRadioButtons
      },
      props: {
         fields: {
            type: Array,
            required: true
         },
         model: {
            required: true
         },
         indexPrefix: {
            type: String,
            required: true
         }
      },
      data() {
         return {
            content: {
               id: 1,
            },
            errorMessage: 'Bitte füllen Sie dieses Pflichtfeld aus.',
         }
      },
      methods: {
         stringToOptions(options) {
            let array = [];
            let optionsArray = options.split('\n');

            for (let i = 0; i < optionsArray.length; i++) {
               array.push({
                  key: optionsArray[i].trim(),
                  value: optionsArray[i].trim()
               });
            }

            return array;
         },
         selectUpdated() {
            let self = this;
            self.fields.forEach(function (field, key) {
               if (field.condition) {
                  self.fields[key].is_visible = self.model[field.condition_field] == field.condition_field_value;
               }

               if (field.condition_not) {
                  self.fields[key].is_visible = self.model[field.condition_not_field] != field.condition_not_field_value;
               }
            })
         }
      }
   }
</script>
