<template>
    <div class="content_element employees_carousel_element">
        <div class="container">
            <nl2br tag="h2" class-name="text-center" :text="content.title"/>
            <div class="employees_carousel_wrapper">
                <swiper class="swiper"
                        ref="swiperCarousel"
                        :options="swiperOption"
                        :auto-update="true"
                        :auto-destroy="true"
                        v-if="employee.carousel && employee.carousel.length"
                >
                    <swiper-slide v-for="(item, index) in employee.carousel" :key="index+'employeeCarouselContent'">
                        <div class="employee_box_item">
                            <div class="employee_box_image_wrapper">
                                <img v-if="item.image" :src="cmsAsset(item.image)" :alt="item.title">
                            </div>
                            <div class="employee_box_content_wrapper">
                                <h2>{{item.name}}</h2>
                                <nl2br tag="p" :text="item.description"/>
                                <p class="employee_box_position">{{item.position}}</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

    export default {
        name: "EmployeesCarousel",
        components: {
            Swiper,
            SwiperSlide
        },
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                swiperOption: {
                    slidesPerView: 1,
                    spaceBetween: 3,
                    breakpoints: {
                        // when window width is >= 320px
                        414: {
                            slidesPerView: 1,
                            spaceBetween: 3,
                        },
                        576: {
                            slidesPerView: 1,
                            spaceBetween: 13
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 23
                        },
                        992: {
                            slidesPerView: 2,
                            spaceBetween: 33
                        },
                        1280: {
                            slidesPerView: 2,
                            spaceBetween: 50
                        }
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    }
                }
            }
        },
        computed: {
            ...mapState([
                'employee',
            ])
        },
        methods: {},
        created() {
            this.$store.dispatch('employee/getCarousel', {
                sort_by: this.content.sort_by,
                order: this.content.order,
                limit: this.content.limit
            });
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
