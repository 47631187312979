<template>
    <div v-if="isVisible">
        <div class="modal" :class="[additionalClass]">
            <div class="abellio_info_box slide-top">
                <header>
                    <div class="header">
                        <a class="close-modal-btn" @click="close">
                            <span>Fenster schliessen</span>
                            <img src="../../assets/images/close-modal-icon.svg" alt="Fenster schliessen" />
                        </a>
                    </div>
                    <div class="content">
                        <slot name="modalContent"></slot>
                    </div>
                </header>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Modal",
        components: {},
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            additionalClass: {
               type: String,
               default: ''
            }
        },
        computed: {
            isVisible() {
                return this.visible;
            },
        },
        methods: {
            close() {
                this.$emit('modalClose', true)
                document.body.classList.remove("modal-open")
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal {
        position: absolute;
        inset: 0;
        background: rgba(107,113,122, 0.25);
        z-index: 992;
        display: flex;
        justify-content: center;
        align-items: center;
        h3 {
            margin-bottom: 40px;
        }
    }
    .abellio_info_box {
        width: 880px;
        position: fixed;
        padding: 30px;
        background: #fff;
        top: 140px;
        left: auto;
        right: auto;
        bottom: -20px;
        // min-height: 760px;
        z-index: 992;
        overflow-y: auto;
        .header {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 35px;
        }
    }
    .close-modal-btn {
        display: flex;
        align-items: center;
        gap: 13.25px;
        cursor: pointer;
        span {
            font-size: 0.8889rem;
            line-height: 1.0667rem;
            font-weight: 700;
            color: $button-background;
            text-decoration: underline;
        }
        img {
            width: 27px;
        }
    }
    @media (max-width: 920px) {
        .abellio_info_box {
            width: 85%;
            top: 60px;
        }
    }
    @media (max-width: 600px) {
        .abellio_info_box {
            min-height: auto;
        }
    }

    @-webkit-keyframes slide-top {
        0% {
            -webkit-transform: translateY(100%);
                    transform: translateY(100%);
        }
        100% {
            -webkit-transform: translateY(0%);
                    transform: translateY(0%);
        }
    }
    @keyframes slide-top {
        0% {
            -webkit-transform: translateY(100%);
                    transform: translateY(100%);
        }
        100% {
            -webkit-transform: translateY(0%);
                    transform: translateY(0%);
        }
    }
    .slide-top {
        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
</style>