<template>
   <div class="content_element accordion_element">
      <div class="container">
         <div class="accordion_wrapper">
            <div class="accordion_item accordion_form" :class="{ 'is_open' : visible }">
               <h6 class="accordion_title mb0" @click="visible = !visible" v-text="content.title"></h6>
               <transition name="slide">
                  <FormBuilder v-if="visible" :showTitle="false" :id="content.id"
                               :content="{id: content.id, title: content.title, form_elements: content.questions, success_message: content.success_message[0],template:content._template}"/>
               </transition>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import FormBuilder from "./FormBuilder";

   export default {
      name: "AccordionForm",
      props: {
         content: {
            type: Object,
            required: true
         }
      },
      components: {
         FormBuilder
      },
      data() {
         return {
            visible: false
         }
      }
   }
</script>
