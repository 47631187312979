<template>
   <div class="container">
      <div
         class="a-warning"
         @click="isContentOverflowing = !isContentOverflowing"
         :class="{ of: isContentOverflowing }"
      >
         <div class="container">
            <div class="row">
               <div
                  class="col-xl-2 col-lg-2 col-md-2 col-sm-10 col-12 d-flex align-items-center justify-content-center"
               >
                  <i class="icon ab-icon-warning"></i>
               </div>
               <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                  <h4 class="mb5">{{ content.title }}</h4>
                  <div class="content mb0" v-html="content.content"></div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   export default {
      name: "WarningMessage",
      props: {
         content: {
            type: Object,
            required: true,
         },
      },
      data() {
         return {
            isContentOverflowing: true,
         };
      },
      mounted() {
         let c = this.$refs.content;
         // debugger;
         if (c && (c.scrollHeight <= c.clientHeight)) this.isContentOverflowing = false;
      },
   };
</script>
