<template>
    <div class="content_element job_search_element text-center">
        <div class="container">
            <div class="job_search_wrapper">
                <h2>{{content.title}}</h2>
                <form action="">
                    <div class="row text-left">
                        <div class="col-12">
                            <InputText
                                :name="jobTitle.name"
                                :label="jobTitle.label"
                                :placeholder="jobTitle.placeholder"
                                :inputId="jobTitle.name+'_input'"
                                v-model="model[jobTitle.name]"
                                :required="true"
                                :disabled="false"
                            />
                        </div>
                        <div class="col-6">
                            <InputText
                                :name="place.name"
                                :label="place.label"
                                :placeholder="place.placeholder"
                                :inputId="place.name+'_input'"
                                v-model="model[place.name]"
                                :required="true"
                                :disabled="false"
                            />
                        </div>
                        <div class="col-6">
                            <InputSelect
                                :name="perimeter.name"
                                :label="perimeter.label"
                                :placeholder="perimeter.placeholder"
                                :inputId="perimeter.name+'_input'"
                                v-model="model[perimeter.name]"
                                :options="perimeter.options"
                                :required="true"
                                :disabled="false"
                            />
                        </div>
                    </div>
                    <button class="btn-grey">{{content.button_title}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        InputText,
        InputSelect
    } from '../../components/_form';

    export default {
        name: "JobsSearch",
        components: {
            InputText,
            InputSelect,
        },
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                model: {},
                jobTitle: {
                    name: 'job_title',
                    label: 'Jobbezeichnung',
                    placeholder: 'Jobbezeichnung eingeben',
                    value: '',
                },
                place: {
                    name: 'place',
                    label: 'Ort',
                    placeholder: 'Ort eingeben',
                    value: '',
                },
                perimeter: {
                    name: 'perimeter',
                    label: 'Umkreis',
                    placeholder: 'Umkreis wählen',
                    options: [
                        {
                            key: '10',
                            value: '10 km'
                        },
                        {
                            key: '50',
                            value: '50 km'
                        },
                        {
                            key: '100',
                            value: '100 km'
                        },
                        {
                            key: '500',
                            value: '500 km'
                        }
                    ],
                }
            }
        },
        computed: {
        }
    }
</script>

<style scoped>

</style>
