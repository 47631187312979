<template>
   <div
      class="content_element timetable_element timetable_result_banner"
      v-if="content.available != '0'"
   >
      <div class="timetable_wrapper">
         <div class="timetable_inner_wrapper">
            <div class="timetable_inner_inner">
               <h5 class="timetable_title font-weight-light">Verbindungen</h5>
               <div
                  class="timetable_inner_result_wrapper d-flex justify-content-center"
               >
                  <div class="">
                     <div class="timetable_inner_result_addresses mb20">
                        <div class="timetable_inner_result_line">
                           <span class="colored-dot-icon white"></span>
                           <span class="colored-dot-icon white"></span>
                        </div>
                        <p class="mb15">{{ timetableFrom }}</p>
                        <p>{{ timetableTo }}</p>
                     </div>
                     <p class="font-weight-light text-center">
                        {{ $moment(date, "DD.MM.YYYY").format("DD.MM.YYYY") }},
                        {{ time }} Uhr
                     </p>
                  </div>
               </div>
            </div>
         </div>

         <div
            class="timetable_submit_wrapper d-flex flex-column align-items-center"
         >
            <button
               class="btn btn-suche animate__fadeInUp"
               @click="$router.push('/')"
            >
               {{ __t("back") }}
            </button>
         </div>
      </div>
      <div class="container timetable_results_content">
         <div v-if="!selectedOption">
            <template v-if="region.currentRegion.id == 1">
               <div
                  class="timetable_information_extra_wrapper extra-button d-inline-block ml-3 inline-w-heading"
               >
                  <a
                     href="#"
                     @click.prevent="extrasActive = true"
                     class="timetable_information_extra_trigger"
                  >
                     <span class="icon">
                        <svg
                           id="Icon_Small_More_Lightgrey"
                           data-name="Icon/Small/More/Lightgrey"
                           xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink"
                           width="30"
                           height="30"
                           viewBox="0 0 30 30"
                        >
                           <g
                              id="Group_3"
                              data-name="Group 3"
                              transform="translate(4 4)"
                           >
                              <g
                                 id="Group_3-2"
                                 data-name="Group 3"
                                 clip-path="url(#clip-path)"
                              >
                                 <path
                                    id="Fill_1"
                                    data-name="Fill 1"
                                    d="M10.75,21.5A10.75,10.75,0,1,1,21.5,10.75,10.762,10.762,0,0,1,10.75,21.5ZM16.2,8.75a2,2,0,1,0,2,2A2,2,0,0,0,16.2,8.75Zm-5.447,0a2,2,0,1,0,2,2A2,2,0,0,0,10.75,8.75ZM5.3,8.75a2,2,0,1,0,2,2A2,2,0,0,0,5.3,8.75Z"
                                    transform="translate(0)"
                                    fill="#6E6E6E"
                                 />
                              </g>
                           </g>
                        </svg>
                     </span>
                  </a>
                  <div
                     @click.prevent="extrasActive = false"
                     class="timetable_information_extras_overlay"
                     :class="{ is_active: extrasActive }"
                  ></div>
                  <div
                     class="timetable_information_extras"
                     :class="{ is_active: extrasActive }"
                  >
                     <a
                        href="#"
                        class="timetable_information_extras_item"
                        @click="saveRoute"
                        >Fahrt speichern</a
                     >
                     <a
                        href="#"
                        class="timetable_information_extras_item"
                        v-clipboard="getUrl()"
                        v-clipboard:success="urlCopied"
                        >Teilen (URL kopieren)</a
                     >
                     <a
                        href="https://abrm-webshop.de/Shop/ProductSearch"
                        target="_blank"
                        class="timetable_information_extras_item"
                        v-if="region.currentRegion.id === 1"
                     >
                        Ticket kaufen
                     </a>
                  </div>
               </div>

               <iframe
                  :src="insaIframeUrl"
                  style="width: 100%; height: 1000px"
                  frameborder="0"
                  data-autosubmit="1"
                  id="iframe"
               ></iframe>
            </template>
            <template v-else>
               <div
                  class="filter_list"
                  v-if="timetableInformation.timetableLoaded"
               >
                  <ul>
                     <li
                        v-for="(filter, index) in availableFilters"
                        :key="index"
                        class="cursor-pointer"
                        :class="{ active: activeFilters.includes(filter.id) }"
                     >
                        <a @click.prevent="toggleFilter(filter.id)">{{
                           filter.name
                        }}</a>
                     </li>
                  </ul>
               </div>

               <div class="timetable_result_list">
                  <a
                     v-if="
                        timetableInformation.timetableLoaded &&
                           timetableInformation.timetable.options.length == 0
                     "
                     class="text-center"
                     @click="step = 1"
                  >
                     Es wurden keine Fahrten gefunden. Versuchen Sie, den Start-
                     und Zielbahnhof genauer anzugeben.
                  </a>

                  <a
                     v-for="(option, index) in timetableOptions"
                     :key="index"
                     @click="viewDetails(option)"
                     class="timetable_result_list_item"
                  >
                     <div class="timetable_result_list_item_part1">
                        <div class="start_time">{{ option.departureTime }}</div>
                        <div class="road_parts_wrapper">
                           <template v-for="(leg, legIndex) in option.legs">
                              <div class="road_parts_item" :key="legIndex">
                                 <span class="road_parts_arrow"> </span>
                                 {{ leg.points[0].name }}
                              </div>
                           </template>
                        </div>
                        <div class="end_time">
                           <span class="icon">
                              <span class="colored-dot-icon red"></span>
                           </span>

                           {{ option.arrivalTime }}
                        </div>
                     </div>
                     <div class="timetable_result_list_item_part2 mb15">
                        <div class="timetable_result_list_item_time">
                           <span class="icon">
                              <i class="ab-icon-hour"></i>
                           </span>

                           {{ $moment.duration(option.duration).hours() }}h
                           {{ $moment.duration(option.duration).minutes() }}m
                        </div>

                        <div class="timetable_result_list_item_changes">
                           Umstiege: {{ option.interchange }}
                        </div>
                     </div>
                     <div class="text-center">
                        {{ option.startStation }} - {{ option.endStation }}
                     </div>
                  </a>
               </div>
            </template>
         </div>

         <template v-if="selectedOption">
            <a href="#" @click="closeDetails" class="close close-timetable">
               <i class="ab-icon-arrow-left mt5 mr10"></i>
               Fahrplan ändern
            </a>
            <TimetableTravelInformation :data="selectedOption" />
         </template>
      </div>
   </div>
</template>

<script>
import $ from "jquery";
import { mapActions, mapState } from "vuex";
import TimetableTravelInformation from "../../components/_common/TimetableTravelInformation";

export default {
   name: "Timetable",
   components: {
      TimetableTravelInformation
   },
   props: {
      content: {
         type: Object,
         required: true
      }
   },
   data() {
      return {
         step: 1,
         selectedOption: null,
         date: this.$moment().format("DD.MM.YYYY"),
         time: this.$moment().format("HH:mm"),
         departingTrains: "",

         timetableFrom: "",
         timetableTo: "",

         filter: "",
         minCharsSearchStart: 0,
         activeFilters: [],
         extrasActive: false
      };
   },
   computed: {
      ...mapState([
         "locales",
         "region",
         "autoSuggestPlaces",
         "timetableInformation"
      ]),
      timeComputed() {
         //if (this.time == this.$moment().format("HH:mm")) {
         //   return "Jetzt";
         //}
         return this.time;
      },
      insaIframeUrl() {
         return (
            "https://reiseauskunft.insa.de/hafas-res/vs_webapp/index.html?language=de_DE&#!P|TP!S|" +
            this.timetableFrom +
            "!Z|" +
            this.timetableTo +
            "+S" +
            "!date|" +
            this.$moment(this.date, "DD.MM.YYYY").format("DD.MM.YYYY") +
            "!time|" +
            this.$moment(this.time, "HH:mm").format("HH:mm") +
            "!timeSel|" +
            "|depart"
         );
      },
      timetableOptions() {
         let result = [];
         let self = this;
         this.timetableInformation.timetable.options.forEach(function(option) {
            option.legs.forEach(function(leg) {
               if (
                  self
                     .$moment(option.departureTime, "HH:mm")
                     .isSameOrAfter(self.$moment(self.time, "HH:mm"))
               ) {
                  if (self.activeFilters.length === 0) {
                     result.push(option);
                  } else if (self.activeFilters.includes(leg.modeId)) {
                     result.push(option);
                  }
               }
            });
         });

         return result;
      },
      availableFilters() {
         let result = [];

         this.timetableInformation.timetable.filter.forEach(function(filter) {
            if (filter.has) {
               result.push(filter);
            }
         });

         return result;
      }
   },
   methods: {
      ...mapActions("savedRoutes", ["saveRoutes"]),
      saveRoute() {
         const sData = {
            departure: this.timetableFrom,
            arrival: this.timetableTo,
            date: this.$route.query.date,
            url: ""
         };
         this.saveRoutes(sData);

         this.$store.dispatch("alert/success", {
            title: "Success",
            message: this.__t("timetable.trip_saved_successfully")
         });

         this.extrasActive = false;
      },
      toggleFilter(id) {
         if (this.activeFilters.includes(id)) {
            this.activeFilters = this.activeFilters.filter(i => i !== id);
            return;
         }

         this.activeFilters.push(id);
      },
      loadTimeTable() {
         let self = this;
         this.$store.commit("loading/setFullPageLoaderVisible", true);
         this.$store
            .dispatch("timetableInformation/getTimetable", {
               from: this.timetableFromObject.id,
               to: this.timetableToObject.id,
               date: this.date,
               time: this.time
            })
            .then(data => {
               data.filter.forEach(function(filter) {
                  self.activeFilters.push(filter.id);
               });
               this.$store.commit("loading/setFullPageLoaderVisible", false);
            });
      },
      viewDetails(option) {
         this.selectedOption = option;
      },
      closeDetails() {
         this.selectedOption = null;
      },
      back() {
         this.$store.commit("timetable/setData", {
            date: this.date,
            time: this.time,
            timetableFrom: this.timetableFrom,
            timetableTo: this.timetableTo,
            timetableFromObject: this.timetableFromObject,
            timetableToObject: this.timetableToObject
         });
         this.$store.commit("globalPopup/setTimetableOpen", true);
      },
      urlCopied() {
         this.$store.dispatch("alert/success", {
            title: "Succes",
            message: "Url copied succesfully"
         });
      },
      getUrl() {
         return document.URL;
      }
   },
   mounted() {
      const _self = this;
      $(document).mouseup(function(e) {
         const containerFrom = $(".timetable_from_wrapper");
         const containerTo = $(".timetable_to_wrapper");
         if (
            !containerFrom.is(e.target) &&
            containerFrom.has(e.target).length === 0
         ) {
            _self.timetableFromActive = false;
         }
         if (
            !containerTo.is(e.target) &&
            containerTo.has(e.target).length === 0
         ) {
            _self.timetableToActive = false;
         }
      });
   },
   created() {
      if (
         this.$route.query.from !== undefined &&
         this.$route.query.to !== undefined &&
         this.$route.query.fromName !== undefined &&
         this.$route.query.toName !== undefined &&
         this.$route.query.date !== undefined &&
         this.$route.query.time !== undefined
      ) {
         this.timetableFromObject = {
            id: this.$route.query.from,
            name: this.$route.query.fromName
         };
         this.timetableFrom = this.$route.query.fromName;
         this.timetableToObject = {
            id: this.$route.query.to,
            name: this.$route.query.toName
         };
         this.timetableTo = this.$route.query.toName;

         this.date = this.$moment(this.$route.query.date, "DD.MM.YYYY").format(
            "DD.MM.YYYY"
         );
         this.time = this.$moment(this.$route.query.time, "HH:mm").format(
            "HH:mm"
         );

         this.step = 2;

         // Load time table only if Region is different than MD
         if (this.region.currentRegion.id !== 1) {
            this.loadTimeTable();
         }
      } else {
         this.$router.push({
            name: "home",
            params: {
               locale: this.$route.params.locale
            }
         });
      }
   }
};
</script>

<style lang="scss" scoped>
.timetable_element {
   background: $light-grey;
   margin-bottom: 0;
   padding-bottom: 30px;
}

.hfs {
   background: red;
}

.extra-button {
   float: right;
   margin-right: 10px;
   margin-bottom: -50px;
   z-index: 8;
}
</style>
