<template>
    <div class="content_element columns_content_element">
        <div class="container">
            <div class="row justify-content-center" v-for="(row, index) in rows" :key="index+'columns_content_row'">
                <div :class="'col-'+(12/columns)" v-for="(item, index2) in row" :key="index+index2+'columns_content'">
                    <div class="columns_content_wrapper">
                        <nl2br tag="h6" :text="item.title" class-name="columns_content_title" />
                        <div v-html="item.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';

    export default {
        name: "ColumnsContents",
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                columns: this.content.columns
            }
        },
        computed: {
            rows: function () {
                let rows = [];
                let i,j,temparray;
                
                for (i = 0, j = this.content.contents.length; i < j; i += this.columns) {
                    temparray = this.content.contents.slice(i,i+this.columns);
                    rows.push(temparray);
                }
                
                return rows;
            }
        },
        methods: {
            titlesHeight: function () {
                if(window.innerWidth < 576) {
                    this.columns = 1;
                } else if(window.innerWidth < 768 && this.content.columns > 2) {
                    this.columns = 2;
                } else {
                    this.columns = this.content.columns;
                }

                this.$nextTick(() => {
                    const wrapper = $(this.$el);
                    wrapper.find('.row').each(function() {
                        let maxTitleHeight = 0;
                        $(this).find('.columns_content_title').css('height', 'auto');
                        $(this).find('.columns_content_title').each(function() {
                            maxTitleHeight = ($(this).outerHeight() > maxTitleHeight) ? $(this).outerHeight() : maxTitleHeight;
                        });
                        $(this).find('.columns_content_title').outerHeight(maxTitleHeight);
                    });
                });
            }
        },
        mounted() {
            this.titlesHeight();
            window.addEventListener('resize', this.titlesHeight)
        }
    }
</script>

<style scoped>

</style>
