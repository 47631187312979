<template>
   <div class="page_section">
      <div class="content_element abellio_world_element mb0">
         <div style="display: none" @click.prevent="extrasActive = false"
              class="timetable_information_extras_overlay" :class="{ is_active: extrasActive }"></div>
      </div>
      <div class="d-flex flex-wrap abellio_world_filter_wrapper mb0" :class="{is_open: isOpen}">

         <a href="#" class="abellio_world_filter_close" @click.prevent="isOpen = !isOpen"></a>

         <div class="container">
            <div class="timetable_information_wrapper" ref="timetableWrapper">
               <h2 class="timetable_information_title d-inline-block">{{ data.interchange }} × Umsteigen </h2>
               <div class="timetable_information_extra_wrapper d-inline-block ml-3 inline-w-heading">
                  <a href="#" @click.prevent="extrasActive = true" class="timetable_information_extra_trigger">
                                <span class="icon">
                                    <svg id="Icon_Small_More_Lightgrey" data-name="Icon/Small/More/Lightgrey"
                                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                         width="30" height="30" viewBox="0 0 30 30">
                                      <g id="Group_3" data-name="Group 3" transform="translate(4 4)">
                                        <g id="Group_3-2" data-name="Group 3" clip-path="url(#clip-path)">
                                          <path id="Fill_1" data-name="Fill 1"
                                                d="M10.75,21.5A10.75,10.75,0,1,1,21.5,10.75,10.762,10.762,0,0,1,10.75,21.5ZM16.2,8.75a2,2,0,1,0,2,2A2,2,0,0,0,16.2,8.75Zm-5.447,0a2,2,0,1,0,2,2A2,2,0,0,0,10.75,8.75ZM5.3,8.75a2,2,0,1,0,2,2A2,2,0,0,0,5.3,8.75Z"
                                                transform="translate(0)" fill="#6E6E6E"/>
                                        </g>
                                      </g>
                                    </svg>
                                </span>
                  </a>
                  <div @click.prevent="extrasActive = false" class="timetable_information_extras_overlay"
                       :class="{ is_active: extrasActive }"></div>
                  <div class="timetable_information_extras" :class="{ is_active: extrasActive }">
                     <a href="#" class="timetable_information_extras_item" @click="saveRoute">Fahrt speichern</a>
                     <a href="#" class="timetable_information_extras_item" @click="generateICal">
                        Zum Kalender hinzufügen
                     </a>
                     <a href="#" class="timetable_information_extras_item">Alarm für Abfahrtszeit erstellen</a>
                     <a href="#" class="timetable_information_extras_item" @click="exportToPDF">Als PDF speichern</a>
                     <a href="#" class="timetable_information_extras_item" v-clipboard="getUrl()"
                        v-clipboard:success="urlCopied">Teilen (URL kopieren)</a>
                     <a href="https://abrm-webshop.de/Shop/ProductSearch" class="timetable_information_extras_item"
                        v-if="region.currentRegion.id === 1">
                        Ticket kaufen
                     </a>
                     <!--                     <a href="#" class="timetable_information_extras_item">-->
                     <!--                                    <span class="icon">-->
                     <!--                                        <svg id="Icon_Small_Plus_Red" data-name="Icon/Small/Plus/Red"-->
                     <!--                                             xmlns="http://www.w3.org/2000/svg"-->
                     <!--                                             xmlns:xlink="http://www.w3.org/1999/xlink" width="26.108" height="26.108"-->
                     <!--                                             viewBox="0 0 26.108 26.108">-->
                     <!--                                            <path id="Clip_2-2" data-name="Clip 2" d="M0,0H26.108V26.108H0Z"-->
                     <!--                                                  transform="translate(0 0)" fill="none"/>-->
                     <!--                                            <g id="Group_3-3" data-name="Group 3" clip-path="url(#clip-path-2)">-->
                     <!--                                                <path id="Fill_1" data-name="Fill 1"-->
                     <!--                                                      d="M13.054,26.108A13.054,13.054,0,1,1,26.108,13.054,13.069,13.069,0,0,1,13.054,26.108Zm-5-13.8v1.5H12.3v4.25h1.5V13.8h4.25V12.3H13.8V8.054H12.3V12.3Z"-->
                     <!--                                                      transform="translate(0 0)" fill="#ffffff"/>-->
                     <!--                                            </g>-->
                     <!--                                        </svg>-->
                     <!--                                    </span>-->
                     <!--                        Lieblingsstrecke</a>-->
                  </div>
               </div>

               <div class="timetable_information_info mt-2">
                  <p>
                                <span class="icon">
                                    <i class="ab-icon-hour"></i>
                                </span>
                     {{ $moment.duration(data.duration).hours() }}h
                     {{ $moment.duration(data.duration).minutes() }}m
                  </p>
<!--                  <p>-->
<!--                                <span class="icon">-->
<!--                                    <i class="ab-icon-marker-o"></i>-->
<!--                                </span>-->
<!--                     205 km-->
<!--                  </p>-->
               </div>

               <div class="timetable_information_stations_wrapper" v-for="(leg, legIndex) in data.legs"
                    :key="legIndex">
                  <div class="timetable_information_stations_item" v-for="(point, pointIndex) in leg.points"
                       :key="pointIndex">
                     <span class="circle"></span>
                     <div class="timetable_information_stations_item_row">
                        <div class="timetable_information_stations_item_col1">
                           <p class="timetable_information_stations_time" style="font-weight: bold;"  :style="{color: point.delayed ? '#ec0021' : '#118937'}">{{ point.realTime}}</p>
                        </div>
                        <div class="timetable_information_stations_item_col2">
                           <h6 class="timetable_information_stations_title">
                              {{ point.name }}
                           </h6>
                        </div>
                     </div>
                     <div class="timetable_information_stations_item_row">
                        <div class="timetable_information_stations_item_col2">
                           <p class="timetable_information_stations_item_info_part">
                              {{ leg.mode }}
                           </p>
                        </div>
                     </div>
                     <div v-if="point.pointType === 'Gleis'" class="timetable_information_stations_item_col2 mb-3">
                        <p class="timetable_information_stations_item_info_part">
                           {{ point.pointType }}: {{ point.platformName }}
                        </p>
                     </div>
                     <div class="timetable_information_stations_item_col2 mb-3">
                        <p class="timetable_information_stations_item_info_part">
                           <template v-if="point.delayed">
                              Laut Fahrplan {{ point.realTime }}
                           </template>
                           <template v-else>
                              voraussichtlich pünktlich
                           </template>
                        </p>
                     </div>
                     <template v-if="point.usage === 'departure'">
                        <button v-if="leg.hints"
                                class="btn timetable_information_stations_item_hint-switch py-0"
                                @click="showHint">
                           mehr <i class="ab-icon-arrow-down"></i>
                        </button>
                        <div class="timetable_information_stations_item_hints">
                           <div class="timetable_information_stations_item_row"
                                v-for="(hint, hintKey) in leg.hints"
                                :key="hintKey">
                              <div class="timetable_information_stations_item_col2">
                                 <p class="timetable_information_stations_item_info_part timetable_information_stations_extra_content">
                                    {{hint}}
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div class="timetable_information_stations_item_row">
                           <div class="timetable_information_stations_item_col1"></div>
                        </div>
                        <div class="timetable_information_stations_item_row" v-if="leg.footpath">
                           <div class="timetable_information_stations_item_col1">
                                        <span class="timetable_information_stations_icon">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 xmlns:xlink="http://www.w3.org/1999/xlink" width="19.569"
                                                 height="7.538" viewBox="0 0 19.569 7.538">
                                              <g id="Group_6" data-name="Group 6" transform="translate(0 -1)">
                                                <g id="Group_3" data-name="Group 3" transform="translate(0 0.538)">
                                                  <path id="Clip_2-2" data-name="Clip 2" d="M0,0H12.736V7.538H0Z"
                                                        transform="translate(0 0.462)" fill="none"/>
                                                  <g id="Group_3-2" data-name="Group 3" clip-path="url(#clip-path)">
                                                    <path id="Fill_1" data-name="Fill 1"
                                                          d="M7.285,0l3.143,3.144H4.91a2.5,2.5,0,1,0,0,1.25h5.516L7.287,7.538H8.964l3.772-3.769L8.964,0Z"
                                                          transform="translate(0 0.462)"/>
                                                  </g>
                                                </g>
                                                <path id="Fill_4" data-name="Fill 4"
                                                      d="M2.5,0A2.5,2.5,0,1,0,5,2.5,2.5,2.5,0,0,0,2.5,0"
                                                      transform="translate(14.569 2.274)"/>
                                              </g>
                                            </svg>
                                        </span>
                           </div>
                           <div class="timetable_information_stations_item_col2">
                              <p class="timetable_information_stations_item_info_part">
                                 Fußweg ca. {{ leg.footpath }} Minuten
                              </p>
                           </div>
                        </div>
                     </template>
                  </div>

               </div>
               <p class="timetable_information_hint">*Gleisangaben können abweichen</p>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import html2pdf from 'html2pdf.js';
   import { mapState, mapActions } from "vuex";

   export default {
      name: "TimetableTravelInformation",
      props: {
         data: Object
      },
      data() {
         return {
            extrasActive: false,
            isOpen: false,
         }
      },
      computed: {
         ...mapState([
            "region",
            'savedRoutes'
         ])
      },
      methods: {
         ...mapActions('savedRoutes', ['saveRoutes']),
         saveRoute() {
            const sData = {
               departure: this.data.startStation,
               arrival: this.data.endStation,
               date: this.$route.query.date,
               url: ''
            }
            this.saveRoutes(sData)

            this.$store.dispatch('alert/success', {
               title: 'Success',
               message: this.__t('timetable.trip_saved_successfully')
            })

            this.extrasActive = false;
         },
         getUrl() {
            return document.URL;
         },
         urlCopied() {
            this.$store.dispatch('alert/success', {
               title: 'Success',
               message: 'Url copied succesfully'
            })
         },
         generateICal() {
            const ical = require('ical-generator');
            let startDate = this.$moment(this.$route.query.date + ' ' + this.$route.query.time, "DD.MM.YYYY HH:mm");
            let endDate = this.$moment(this.$route.query.date + ' ' + this.$route.query.time, "DD.MM.YYYY HH:mm").add({
               hours: this.$moment(this.data.duration, "HH:mm").hours(),
               minutes: this.$moment(this.data.duration, "HH:mm").minutes()
            });

            let cal = ical({
               domain: 'abellio.de',
               prodId: '//abellio.de//ical-generator//DE',
               events: [
                  {
                     start: startDate.toDate(),
                     end: endDate.toDate(),
                     summary: this.data.startStation + ' ' + this.data.endStation
                  }
               ]
            }).toURL();

            const anchor = document.createElement('a');
            anchor.href = cal;
            anchor.target = "_blank";
            anchor.download = this.data.startStation + ' ' + this.data.endStation + '.ics';
            anchor.click();
            URL.revokeObjectURL(cal);
         },
         exportToPDF() {
            this.extrasActive = false
            this.$nextTick(() => {
               setTimeout(() => {
                  html2pdf().set({
                     margin: 1,
                     filename: this.data.startStation + '-' + this.data.endStation + '.pdf',
                     enableLinks: false,
                     image: {type: 'jpeg', quality: 1},
                     html2canvas: {dpi: 50, letterRendering: true},
                     jsPDF: {unit: 'cm', format: 'a4', orientation: 'portrait'}
                  }).from(this.$refs.timetableWrapper).save()
               }, 1000)
            })
            // html2pdf(this.$refs.timetableWrapper, {
            //    margin: 1,
            //    filename: 'document.pdf',
            //    image: { type: 'jpeg', quality: 0.98 },
            //    html2canvas: { dpi: 192, letterRendering: true },
            //    jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' }
            // })
         },
         showHint: function (e) {
            e.currentTarget.classList.toggle('is-active');
            e.currentTarget.nextElementSibling.classList.toggle('is-active');
         }
      }
   }
</script>

<style scoped>

</style>
