<template>
    <div class="content_element content_blocks_element">
        <div class="container">
            <div class="row content_block_item">
                <div class="col-12">
                    <div class="content_block_content">
                        <div class="html-content" v-html="content.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Content",
        props: {
            content: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
