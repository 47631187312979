<template>
    <div>
        <GmapMarker
            v-for="(item, index) in office.all"
            :key="index+'office_marker'"
            :position="{lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)}"
            :clickable="true"
            @click="clicked(item)"
            :icon="{url: cmsAsset(settings.all.abellio_marker)}"/>

        <GmapInfoWindow
            v-if="currentInfoWindow"
            :options="{
                maxWidth: 200,
                pixelOffset: {
                    width: 0,
                    height: -48
                }
            }"
            :position="{lat: parseFloat(currentInfoWindow.latitude), lng: parseFloat(currentInfoWindow.longitude)}"
            :opened.sync="infoWindowOpen"
            @closeclick="infoWindowOpen = false; currentInfoWindow = null;">
            <div class="info-window">
                <div class="abellio_info_box">
                    <div class="abellio_info_box_content">
                        <h6 class="abellio_info_box_title" v-if="currentInfoWindow.title">
                            {{ currentInfoWindow.title }}
                        </h6>
                        <p class="abellio_info_box_address">
                            <i class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14.385" viewBox="0 0 10 14.385">
                                    <path id="Shape" d="M5,14.385H5C4.954,14.324,0,8.406,0,5A5,5,0,1,1,10,5c0,3.237-4.944,9.322-4.994,9.383ZM5,2.822a2.133,2.133,0,1,0,1.508.625A2.121,2.121,0,0,0,5,2.822Z" transform="translate(0)" fill="#ffffff"></path>
                                </svg>
                            </i>
                            {{ currentInfoWindow.street }} {{ currentInfoWindow.zip }} {{ currentInfoWindow.city.title }}
                        </p>
                        <a href="#" @click.prevent="showContent(currentInfoWindow)" class="abellio_info_box_more"> mehr erfahren </a>
                    </div>
                </div>
            </div>
        </GmapInfoWindow>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "AbellioWorldAbellioOffices",
        components: {},
        props: {
            settings: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                infoWindowOpen: false,
                currentInfoWindow: null
            }
        },
        created() {
            this.$store.dispatch('office/getAll');
        },
        computed: {
            ...mapState([
                'office'
            ])
        },
        methods: {
            clicked(office) {
                this.infoWindowOpen = true;
                this.currentInfoWindow = office;
                this.$emit('markerClicked', {
                    lat: parseFloat(office.latitude),
                    lng: parseFloat(office.longitude),
                }, this);
            },
            showContent(item) {
                this.infoWindowOpen = false;
                this.currentInfoWindow = null;
                this.$emit('showContent', item, 'office');
            }
        },
    }
</script>
