<template>
    <div class="content_element traffic_infobox_element">
        <div class="container_small">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb30">
                    <div class="traffic_infobox_wrapper">
                        <div class="traffic_infobox_title">
                            <h6>Aktuelle Verkehrsmeldungen</h6>
                        </div>
                        <div class="traffic_infobox_container">
                            <swiper class="swiper pb20"
                                    ref="swiperCarousel"
                                    :options="swiperOption"
                                    :auto-update="true"
                                    :auto-destroy="true"
                                    v-if="currentTrafficNews && currentTrafficNews.length"
                            >
                                <swiper-slide v-for="(items, index) in chunk(currentTrafficNews, 3)"
                                              :key="index+'trafic_block_1'">

                                    <router-link class="traffic_infobox_item"
                                                 v-for="(item, index2) in items" :key="index+'trafic_block_1'+index2"
                                                 :to="{ name: 'traffic-news-message', params: {id: item.id} }">
                                        <span class="item_color" :style="{ backgroundColor: item.color }"></span>
                                        <span>{{item.title}}</span>
                                        <template v-if="item.sub_title">
                                            ({{item.sub_title}})
                                        </template>
                                    </router-link>
                                </swiper-slide>
                                <div class="swiper-pagination traffic_infobox_pagination pagination1"
                                     slot="pagination"></div>
                            </swiper>
                            <p v-else>
                                Es gibt aktuell keine Meldung.
                                <br/>
                                Gute Fahrt!
                            </p>
                        </div>
                        <router-link class="traffic_infobox_overview"
                                     :to="{name: 'traffic-news', params: {locale: locales.currentLocale}}"
                                     v-if="currentTrafficNews && currentTrafficNews.length">
                            zur Übersicht
                        </router-link>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb30">
                    <div class="traffic_infobox_wrapper">
                        <div class="traffic_infobox_title">
                            <h6>Aktuelle Baumaßnahmen</h6>
                        </div>
                        <div class="traffic_infobox_container">
                            <swiper class="swiper pb20"
                                    ref="swiperCarousel"
                                    :options="swiperOption"
                                    :auto-update="true"
                                    :auto-destroy="true"
                                    v-if="currentBuildingMeasures && currentBuildingMeasures.length"
                            >
                                <swiper-slide v-for="(items, index) in chunk(currentBuildingMeasures, 3)"
                                              :key="index+'trafic_block_1'">
                                    <router-link class="traffic_infobox_item"
                                                 v-for="(item, index2) in items" :key="index+'trafic_block_1'+index2"
                                                 :to="{ name: 'construction-work-message', params: {id: item.id} }">
                                        <span class="item_color" :style="{ backgroundColor: item.color }"></span>
                                        <span>{{item.title}}</span>
                                        <template v-if="item.sub_title">
                                            ({{item.sub_title}})
                                        </template>
                                    </router-link>
                                </swiper-slide>
                                <div class="swiper-pagination traffic_infobox_pagination pagination1"
                                     slot="pagination"></div>
                            </swiper>

                            <p v-else>
                                Es gibt aktuell keine Meldung.
                                <br/>
                                Gute Fahrt!
                            </p>
                        </div>
                        <router-link class="traffic_infobox_overview"
                                     :to="{name: 'traffic-news', params: {locale: locales.currentLocale}}"
                                     v-if="currentBuildingMeasures && currentBuildingMeasures.length">
                            zur Übersicht
                        </router-link>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb30">
                    <div class="traffic_infobox_wrapper">
                        <div class="traffic_infobox_title">
                            <h6>Sonderhinweise</h6>
                        </div>
                        <div class="traffic_infobox_container">
                            <p>
                                Es gibt aktuell keine Meldung.
                                <br/>
                                Gute Fahrt!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
    import {mapState} from "vuex";

    export default {
        name: "TrafficInfoBlocks",
        components: {
            Swiper,
            SwiperSlide
        },
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                swiperOption: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        dynamicBullets: true
                    }
                },


            }
        },
        computed: {
            ...mapState([
                'region',
                'settings',
                'locales',
                'trafficNews',
                'constructionWork'
            ]),
            currentBuildingMeasures() {
                let result = [];
                this.constructionWork.all.forEach((item) => {
                    result.push({
                        id: item.id,
                        title: item.line.title,
                        sub_title: item.from_station && item.to_station ? item.from_station + ' – ' + item.to_station : item.title,
                        color: item.line.color
                    });
                });

                return result;
            },
            currentTrafficNews() {
                let result = [];
                this.trafficNews.all.forEach((item) => {
                    result.push({
                        id: item.id,
                        title: item.line.title,
                        sub_title: item.from_station && item.to_station ? item.from_station + ' – ' + item.to_station : item.title,
                        color: item.line.color
                    });
                });

                return result;
            },
        },
        methods: {
            chunk: function (arr, size) {
                let newArr = [];
                for (let i = 0; i < arr.length; i += size) {
                    newArr.push(arr.slice(i, i + size));
                }
                return newArr;
            },


        },
        created() {
            this.$store.dispatch('trafficNews/getAll');
            this.$store.dispatch('constructionWork/getAll');
        },
        watch: {
            'region.currentRegion': function () {
                this.$store.dispatch('trafficNews/getAll');
                this.$store.dispatch('constructionWork/getAll');
            }
        }
    }
</script>

<style scoped>

</style>
