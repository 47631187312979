<template>
    <div class="content_element content_blocks_element">
        <div class="container">
            <div class="row content_block_item justify-content-center"
                 v-for="(item, index) in content.contents" :key="index">
                <div class="col-12 col-md-7 mb30" :class="{ 'text-center': !item.image }">
                    <div class="content_block_content">
                        <h2>{{item.title}}</h2>
                        <div class="html-content" v-html="item.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContentBlocks",
        props: {
            content: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .gray-colored-btn {
        background-color: $abellio-gray;
    }
</style>
