<template>
   <div class="content_element scroll_to_element" ref="scroll_to"></div>
</template>

<script>
   export default {
      name: "ScrollTo",
      props: {
         content: {
            type: Object,
            required: true
         }
      },
      mounted() {
         setTimeout(() => {
            window.scrollTo({
               top: (this.$refs.scroll_to.getBoundingClientRect().top + (parseInt(this.content.offset ?? 0))),
               behavior: 'smooth'
            });
         }, 500)
      }
   }
</script>
