<template>
   <div>
<!--       Text Block section -->
         <div class="content-block">
            <div class="container">
               <div class="row">
                  <div class="col-xl-8 col-12 m-auto" :class="[ 'text-' + content.alignment]">
                     <h3 v-text="content.title"></h3>
                     <p v-html="content.content"></p>
                  </div>
               </div>
            </div>
         </div>
   </div>
</template>

<script>
   export default {
      name: "TextBlock",
      props: {
         content: {
            type: Object,
            required: true
         }
      }
   }
</script>
