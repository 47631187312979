<template>
    <div class="content_element">
        <Accordion v-for="(faq, title) in faq.all" :key="title" :content="{title: title, rows: faq}"/>
    </div>

</template>

<script>
    import {mapState} from "vuex";
    import Accordion from "./Accordion";
    // import Accordion from "./Accordion";

    export default {
        name: "MainForm",
        components: {
            Accordion
            // Accordion,
        },
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapState([
                'faq',
            ])
        },
        mounted() {
            this.$store.dispatch('faq/getAll');
        }
    }
</script>

<style scoped>

</style>
