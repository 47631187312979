<template>
    <div class="content_element place_info_element">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="info_tag_btn" v-for="(tag, index) in content.tags" :key="index+'tag'">
                        <span class="color" :style="{ backgroundColor: tag.color }"></span>
                        <nl2br :text="tag.title" tag="p" />
                    </div>
                </div>
                <div class="col-12 col-md-4 address_box_column">
                    <div class="h-100 w-100 d-flex justify-content-md-end align-items-start justify-content-center">
                        <div class="address_box_wrapper">
                            <h5 class="address_box_title">Anschrift</h5>
                            <h6 class="address_box_subtitle">{{content.address_title}}</h6>
                            <nl2br tag="p" :text="content.address_description" />
                            <a v-if="content.address_link" :href="content.address_link" class="btn btn-grey">Route berechnen</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PlaceInfo",
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        computed: {
        }
    }
</script>

<style scoped>

</style>
