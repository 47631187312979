<template>
  <div class="content_element sub_navigation_element">
    <div class="container">
      <div
        class="sub_navigation_wrapper"
        :class="{ is_centralized: is_centralized }"
      >
        <a href="#" class="abellio-swiper-button-prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
          >
            <g
              id="Icon_Medium_SimpleArrow_Black"
              data-name="Icon/Medium/SimpleArrow/Black"
              transform="translate(0 40) rotate(-90)"
            >
              <path
                id="Fill_1"
                data-name="Fill 1"
                d="M10.355,0,0,10.362v2.1L10.355,2.109,20.709,12.454V10.362Z"
                transform="translate(10 14)"
              />
            </g>
          </svg>
        </a>
        <a href="#" class="abellio-swiper-button-next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
          >
            <g
              id="Icon_Medium_SimpleArrow_Black_Copy"
              data-name="Icon/Medium/SimpleArrow/Black Copy"
              transform="translate(40) rotate(90)"
            >
              <path
                id="Fill_1"
                data-name="Fill 1"
                d="M10.355,0,0,10.362v2.1L10.355,2.109,20.709,12.454V10.362Z"
                transform="translate(10 14)"
              />
            </g>
          </svg>
        </a>
        <swiper class="swiper" :options="swiperOption" ref="swiperCarousel">
          <swiper-slide
            v-for="(item, index) in content.items"
            :key="index + 'sub_navigation'"
          >
            <router-link
              class="sub_navigation_item"
              :to="(item.page !== parentPage) ? parentPage + item.page : item.page"
              v-if="item.page"
              :ref="'sub-navigation-item-' + index"
            >
              <div class="sub_navigation_item_inner">
                <!-- <div
                  class="icon_wrapper d-flex justify-content-center align-items-center"
                >
                  <span class="icon" v-html="item.icon_raw">
                    {{ item.icon }}
                    {{ item.icon_raw }}
                  </span>
                </div> -->
                <nl2br
                  tag="h6"
                  :text="item.title"
                  class-name="sub_navigation_item_title"
                />
              </div>
            </router-link>
            <a :href="item.link" class="sub_navigation_item" v-else>
              <div class="sub_navigation_item_inner">
                <div
                  class="icon_wrapper d-flex justify-content-center align-items-center"
                >
                  <span class="icon" v-html="item.icon_raw">
                    {{ item.icon }}
                    {{ item.icon_raw }}
                  </span>
                </div>
                <nl2br
                  tag="h6"
                  :text="item.title"
                  class-name="sub_navigation_item_title"
                />
              </div>
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { config } from "../../config";

export default {
  name: "SubNavigation",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      is_centralized: false,
      swiperOption: {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        spaceBetween: 16,
        breakpoints: {
          // when window width is >= 320px
          // 320: {
          //   slidesPerView: 1,
          //   spaceBetween: 15,
          // },
          // 376: {
          //   slidesPerView: 2,
          //   spaceBetween: 15,
          // },
          // 460: {
          //   slidesPerView: 3,
          //   spaceBetween: 15,
          // },
          // 576: {
          //   slidesPerView: 3,
          //   spaceBetween: 15,
          // },
          // 768: {
          //   slidesPerView: 3,
          //   spaceBetween: 30,
          // },
          // 992: {
          //   slidesPerView: 4,
          //   spaceBetween: 30,
          // },
          // 1024: {
          //   slidesPerView: 4,
          //   spaceBetween: 30,
          // },
          // 1280: {
          //   slidesPerView: 6,
          //   spaceBetween: 30,
          // },
        },
        navigation: {
          nextEl: ".abellio-swiper-button-next",
          prevEl: ".abellio-swiper-button-prev",
        },
      },
    };
  },
  computed: {
    parentPage() {
      return this.content.parent_page ? this.content.parent_page : "";
    },
    mediaPath() {
      return config.mediaPath;
    },
    swiper() {
      return this.$refs.swiperCarousel.$swiper;
    },
  },
  methods: {
    toSlide(index) {
      this.$refs.swiperCarousel.$swiper.slideTo(index, 0);
    },
    resize() {
      if (this.swiper.isBeginning && this.swiper.isEnd) {
        this.is_centralized = true;
      } else {
        this.is_centralized = false;
      }
    }
  },
  mounted() {
    let elementIndex = $(this.$el)
      .find(".sub_navigation_item.router-link-active")
      .parent()
      .index();
    if (elementIndex != -1) {
      this.toSlide(elementIndex);
    }
   // this.$refs['sub-navigation-item-0'][0].click();
    this.resize();
    window.addEventListener("resize", this.resize);

  }
};
</script>

<style scoped>

.swiper-slide {
  width: auto;
}
</style>
