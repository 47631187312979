<template>
   <div class="content_element carousel_content_element">
      <div class="container">
         <div class="row">
            <!-- <div class="col-12 col-sm-12 col-md-3 col-lg-2">
               <nl2br tag="h2" :text="content.title"/>
            </div> -->
            <div class="col-12">
               <div class="carousel_content_wrapper">
                  <swiper
                     class="swiper"
                     ref="swiperCarousel"
                     :options="swiperOption"
                     :auto-update="true"
                     :auto-destroy="true"
                     v-if="content.contents && content.contents.length">
                     <swiper-slide
                        v-for="(item, index) in content.contents"
                        :key="index + 'carouselContent'">
                        <div class="abellio_info_box" :class="{ is_active: activeContentTitle == item.title }">
                           <div class="abellio_info_box_image"
                                :style="{backgroundImage: 'url(' + cmsAsset(item.image) + ')'}">
                           </div>
                           <!-- <pre>{{ item }}</pre> -->
                           <div class="abellio_info_box_content">
                              <h6 class="abellio_info_box_title">{{ item.title }}</h6>
                              <p v-html="item.short_content"></p>
                           </div>
                           <!-- <a :href="item.btn_link" @click.prevent="openContent(item.title)" class="abellio_info_box_more">
                              Mehr erfahren
                           </a> -->
                            <a :href="item.btn_link" class="abellio_info_box_more">
                              Mehr erfahren
                           </a>
                        </div>
                     </swiper-slide>
                     <div class="swiper-pagination" slot="pagination"></div>

                  </swiper>
                  <div class="arrows">
                        <div slot="button-prev" class="swiper-button-prev"></div>
                        <div slot="button-next" class="swiper-button-next"></div>
                     </div>
               </div>
            </div>
         </div>
      </div>
      <!-- <div class="carousel_big_content_wrapper" v-if="selectedContent && selectedContent.title">
         <Modal
            @modalClose="activeContentTitle = null"
            :visible="true"
            :additionalClass="'map-modal'"
         >
            <template slot="modalContent">
               <div class="container">
                  <div class="row align-items-end">
                     <div class="col-12 col-sm-12 col-lg-6">
                        <div class="image_copyright">
                           <img
                              class="carousel_big_image"
                              v-if="selectedContent.image"
                              :src="cmsAsset(selectedContent.image)"
                              :alt="selectedContent.title"/>
                           <div class="copyright_sign" v-if="selectedContent.copyright">
                              <p>{{ selectedContent.copyright }}</p>
                           </div>
                        </div>
                     </div>
                     <div
                        class="col-12 col-sm-12 col-lg-6 d-flex flex-column justify-content-between carousel_content_side"
                     >
                        <div>
                           <h3 class="mb-0">{{ selectedContent.title }}</h3>
                        </div>
                     </div>
                     <div class="col-12 mt-4">
                        <div class="html-content" v-html="selectedContent.content"></div>
                        <div>
                           <a
                              :href="selectedContent.btn_link"
                              class="info_tag_btn more_btn d-inline-flex justify-content-between"
                              v-if="selectedContent.btn_title && selectedContent.btn_link"
                           >
                              {{ selectedContent.btn_title }}
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </template>
         </Modal>
      </div> -->
   </div>
</template>

<script>
   // import $ from "jquery";
   //  import { Navigation, Pagination } from 'swiper';
   // import Modal from "@/components/_common/Modal";
   import {Swiper, SwiperSlide} from "vue-awesome-swiper";

   export default {
      name: "CarouselContent",
      components: {
         Swiper,
         // Modal,
         SwiperSlide,
      },
      props: {
         content: {
            type: Object,
            required: true,
         },
      },
      data() {
         return {
            swiperOption: {
               slidesPerView: 1.2,
               spaceBetween: 16,
               autoplay: {
                  delay: 5000,
               },
               arrows: true,
               navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
               },
               breakpoints: {
                  // when window width is >= 320px
                  375: {
                     slidesPerView: 1.15,
                     spaceBetween: 16,
                  },
                  768: {
                     slidesPerView: 2,
                     spaceBetween: 16,
                  },
                  992: {
                     slidesPerView: 2.5,
                     spaceBetween: 16,
                  },
                  1280: {
                     slidesPerView: 3,
                     spaceBetween: 32,
                     SwiperSlide: 1
                  },
               },
               pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
               },
            },
            activeContentTitle: null,
         };
      },
      computed: {
         selectedContent: function () {
            return this.content.contents.find((item) => {
               return item.title == this.activeContentTitle;
            });
         },
      },
      methods: {
         // carouselWrapperWidth: function () {
         //    let element = $(this.$el).find(".carousel_content_wrapper");
         //    let offsetLeft = element.offset().left;
         //    element.outerWidth($(this.$el).outerWidth() - offsetLeft);

         //    this.$nextTick(() => {
         //       if (this.$refs.swiperCarousel) {
         //          this.$refs.swiperCarousel.$swiper.update();
         //       }
         //    });
         // },
         openContent(title) {
            this.activeContentTitle = title;
         },
      },
      mounted() {
         // this.carouselWrapperWidth();
         window.addEventListener("resize", this.carouselWrapperWidth);
         // if (this.content.first_open && this.content.contents.length) {
         //    this.activeContentTitle = this.content.contents[0].title;
         // }
      },
   };
</script>

<style scoped>
   .swiper-slide {
      height: auto;
      padding: 5px 0 15px 0;
   }
</style>
