<template>
  <div class="content_element accordion_element jobs-section" v-if="loaded">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="filter_list">
            <!-- <p class="h5 text-center">{{ __t("jobs.categories") }}</p> -->
            <ul>
              <li
                v-for="(category, index) in internalJob.all.categories"
                :key="index"
                class="cursor-pointer"
                :class="{ active: activeCategories.includes(category.id) }"
              >
                <a @click.prevent="toggleCategory(category.id)">
                  {{ category.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <template v-for="(job, index) in internalJob.all.jobs">
          <transition appear name="slide-fade" :key="index">
            <div
              class="col-xl-4 col-lg-6 col-md-12 blog-card-holder"
              :key="index"
            >
              <div class="blog-card large">
                <span style="background-color: #000;">
                  <!-- <pre>{{ job }}</pre> -->
                </span>
                <div class="inner bgcolor">
                  <!-- <div class="blog-card-image" v-if="job.image"
                                   :style="{ backgroundImage: 'url(' + cmsAsset(job.image) + ')' }"></div>

                              <div class="blog-card-image" v-else></div> -->

                  <div class="blog-card-content">
                    <div class="blog-card-content-top">
                      <div class="blog-card-content-top-map">
                        <span class="tab-item sm">
                          {{ job.job_type.title }}
                        </span>
                        <!-- <span class="map-title"><i data-v-1a5e6975="" class="ab-icon-timetable"></i> {{ job.location }}</span> -->
                        <span class="map-title">
                          <span class="ab-icon-location"></span>
                          {{ job.location }}
                        </span>
                      </div>
                      <h3 class="title text-black">{{ job.title }}</h3>
                    </div>
                    <div class="blog-card-content-bottom">
                      <span class="blog-card-content-bottom-date">
                        <span v-text="dateFormat(job)"></span>
                      </span>
                      <div
                        class="blog-card-content-bottom-content"
                        v-html="job.teaser != 'null' ? job.teaser : job.content"
                      ></div>
                      <router-link
                        class="link-style"
                        :to="{ name: 'job', params: { slug: job.slug } }"
                      >
                        Mehr erfahren
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </template>

        <div class="col-sm-12 text-center my-2" v-if="hasMore">
          <button
            class="red-button load-more"
            @click.stop="loadMore()"
            v-if="!loading"
          >
            Mehr laden
          </button>
          <button class="red-button load-more" v-if="loading">
            Wird geladen...
          </button>
        </div>

        <div
          class="col-sm-12 text-center"
          v-if="!loading && internalJob.all.jobs.length === 0"
        >
          <p>{{ __t('jobs.no_jobs') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'JobsList',
  components: {},
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageNr: 0,
      loaded: false,
      hasMore: false,
      loading: false,
      categoriesCount: 0,
      activeCategories: [],
    }
  },
  computed: {
    ...mapState(['internalJob', 'settings']),
  },
  methods: {
    getCategories(article) {
      let categories = []

      article.categories.forEach(function (category) {
        categories.push(category.title)
      })

      return categories
    },
    toggleCategory(id) {
      if (
        this.activeCategories.length === 1 &&
        this.activeCategories[0] === id
      ) {
        return
      }

      if (this.activeCategories.length === this.categoriesCount) {
        this.activeCategories = this.activeCategories.filter((i) => i === id)
      } else if (this.activeCategories.includes(id)) {
        this.activeCategories = this.activeCategories.filter((i) => i !== id)
      } else {
        this.activeCategories.push(id)
      }

      this.$store.commit('internalJob/clearJobs')
      this.pageNr = 0
      this.loadMore()
    },
    loadMore() {
      let self = this
      this.loading = true
      this.pageNr += 1

      this.$store
        .dispatch('internalJob/loadAll', {
          page: this.pageNr,
          categories: this.activeCategories.join(','),
        })
        .then(function (data) {
          if (self.categoriesCount === 0) {
            self.categoriesCount = data.categories.length
          }

          if (self.activeCategories.length === 0) {
            data.categories.forEach(function (category) {
              self.activeCategories.push(category.id)
            })
          }

          self.loading = false
          self.hasMore = data.has_more
          self.loaded = true
        })
    },
    dateFormat(job) {
      if (job && job.updated_at) {
        let dateF = job.updated_at.split(' ')
        dateF = dateF[0].split('-').reverse().join('.')
        return dateF
      }
      return ''
    },
  },
  created() {
    this.internalJob.all.jobs = []
    this.loadMore()
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
   /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
